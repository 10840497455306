import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Checkbox from "antd/lib/checkbox";
import Radio from "antd/lib/radio";
import Form from "antd/lib/form";
import React from "react";
import styled from "styled-components";

import { AppCard } from "components/card";
import { Colors } from "helpers/colors";
import { NephrologyLogo } from "pages/symptoms/surveyForms/nephrologyLogo";
import { Platform } from "constants/roles";

interface UrinarySymptomsSectionProps {
  readonly currentPage: number;
  setShowSubmit(show: boolean): void;
}

export const UrinarySymptomsSection: React.FC<UrinarySymptomsSectionProps> = ({
  currentPage,
  setShowSubmit
}) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  return (
    <>
      {currentPage === 1 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                fontWeight: "600"
              }}
            >
              Please do not rely upon this survey for immediate medical
              attention. This survey may not be reviewed by your care team
              immediately. Please call your doctor for any medical problems. IF
              YOU THINK THIS IS A MEDICAL EMERGENCY, OR YOUR SYMPTOMS ARE
              WORRISOME PLEASE CALL 911 OR GO TO THE NEAREST EMERGENCY ROOM.
            </Space>
          </AppCard>

          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                fontWeight: "600"
              }}
            >
              <NephrologyLogo />
            </Space>
          </AppCard>
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
            >
              <Space
                direction="vertical"
                size={20}
                style={{ width: "100%", padding: "10px" }}
              >
                <CardTitle>
                  Do you want to report any urinary symptoms?
                  <AsteriskStyle> *</AsteriskStyle>
                </CardTitle>
                <FormItemStyle
                  name="Report your urinary symptoms"
                  label=""
                  rules={[
                    {
                      required: true
                    }
                  ]}
                  initialValue={""}
                >
                  <Radio.Group
                    value={localStorage.getItem("reportYourUrinarySymptoms")}
                    onChange={e => {
                      if (e.target.value === "No") {
                        setShowSubmit(true);
                      } else {
                        setShowSubmit(false);
                      }
                      localStorage.setItem(
                        "reportYourUrinarySymptoms",
                        e.target.value
                      );
                    }}
                  >
                    <Space direction="vertical">
                      <Radio value={"Yes"}>Yes</Radio>
                      <Radio value={"No"}>No</Radio>
                    </Space>
                  </Radio.Group>
                </FormItemStyle>
              </Space>
            </AppCard>
          </Space>
        </Space>
      )}
      {currentPage === 2 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <DisclaimerTitle>URINARY SYMPTOMS</DisclaimerTitle>
              <CardTitle>Do you have any of the following symptoms?</CardTitle>
              <FormItemStyle
                name="Any urinary symptoms"
                label=""
                initialValue={""}
              >
                <Checkbox.Group
                  style={{ width: "100%" }}
                  onChange={e =>
                    localStorage.setItem("anyUrinarySymptoms", e.toString())
                  }
                  value={sessionStorage
                    .getItem("anyUrinarySymptoms")
                    ?.split(/,/)}
                >
                  <Row gutter={[10, 10]}>
                    <Col span={24}>
                      <Checkbox value="Urinating too frequently">
                        Urinating too frequently
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="Needing to urinate urgently">
                        Needing to urinate urgently
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="Incontinence of urine">
                        Incontinence of urine
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="Incomplete emptying of your bladder">
                        Incomplete emptying of your bladder
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="Frequent urge to urinate small amounts">
                        Frequent urge to urinate small amounts
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="Poor urinary flow">
                        Poor urinary flow
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </FormItemStyle>
            </Space>
          </AppCard>
        </Space>
      )}
      {currentPage === 3 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <DisclaimerTitle>Urinary Symptoms</DisclaimerTitle>
              PLEASE CALL YOUR DOCTOR IF YOU HAVE ANY OF THE SYMPTOMS BELOW
            </Space>
          </AppCard>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <CardTitle>
                Please indicate if you have any of the following symptoms (and
                contact your doctor)
              </CardTitle>
              <FormItemStyle
                name="Indicate urinary symptoms"
                label=""
                initialValue={""}
              >
                <Checkbox.Group
                  style={{ width: "100%" }}
                  onChange={e =>
                    localStorage.setItem(
                      "indicateUrinarySymptoms",
                      e.toString()
                    )
                  }
                  value={sessionStorage
                    .getItem("indicateUrinarySymptoms")
                    ?.split(/,/)}
                >
                  <Row gutter={[10, 10]}>
                    <Col span={24}>
                      <Checkbox value="Pain or burning with urination">
                        Pain or burning with urination
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="Offensive urine odor">
                        Offensive urine odor
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="Urine that appears cloudy">
                        Urine that appears cloudy
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="Blood in urine">Blood in urine</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </FormItemStyle>
            </Space>
          </AppCard>
        </Space>
      )}{" "}
      {currentPage === 4 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <DisclaimerTitle>Urinary Symptoms</DisclaimerTitle>
              ** PLEASE BE SURE TO CALL YOUR DOCTOR OR SEEK EMERGENCY CARE IF
              YOU ARE REPORTING ANY SYMPTOMS BELOW **
            </Space>
          </AppCard>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <CardTitle>
                Please indicate if you have any of the following symptoms (and
                contact your doctor urgently)
              </CardTitle>
              <FormItemStyle
                name="Indicate urinary symptoms 2"
                label=""
                initialValue={""}
              >
                <Checkbox.Group
                  style={{ width: "100%" }}
                  onChange={e =>
                    localStorage.setItem(
                      "indicateUrinarySymptoms2",
                      e.toString()
                    )
                  }
                  value={sessionStorage
                    .getItem("indicateUrinarySymptoms2")
                    ?.split(/,/)}
                >
                  <Row gutter={[10, 10]}>
                    <Col span={24}>
                      <Checkbox value="Nausea/Vomitting">
                        Nausea/Vomiting
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="Fever/Chills (more than 101F)">
                        Fever/Chills (more than 101F)
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="Feeling unwell">Feeling unwell</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="Confusion/Disorientation">
                        Confusion/Disorientation
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </FormItemStyle>
            </Space>
          </AppCard>
        </Space>
      )}
    </>
  );
};

const DisclaimerTitle = styled.div`
  width: 100%;
  padding: 8px 8px 8px 20px;
  margin-left: -25px;
  background: ${Colors.DodgerBlue};
  font-size: 18px;
  font-family: sans-serif;
  border-radius: 8px 8px 0px 0px;
  color: white;
  position: absolute;
  top: 0;
`;

const FormItemStyle = styled(Form.Item)`
  margin-bottom: 0px;
`;

const AsteriskStyle = styled.span`
  font-weight: 800;
  color: red;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-family: sans-serif;
`;
