import React, { MutableRefObject, useState } from "react";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Checkbox from "antd/lib/checkbox";
import Radio from "antd/lib/radio";
import Form, { RuleObject } from "antd/lib/form";
import moment from "moment";
import styled from "styled-components";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";

import { AppCard } from "components/card";
import { AppDatePicker } from "components/inputs";
import { Colors } from "helpers/colors";
import { NephrologyLogo } from "pages/symptoms/surveyForms/nephrologyLogo";
import { SurveyInput } from "pages/symptoms/surveyForms/surveyInput";
import { SurveyNumberInput } from "pages/symptoms/surveyForms/surveyNumberInput";
import { Platform } from "constants/roles";
import { dateToUTC, disableFutureDate } from "helpers/utils";
import { useEffect } from "react";

interface BloodPressureSectionProps {
  readonly currentPage: number;
  readonly bloodPressureTimeTaken: string;
  readonly otherTempInput: string;
  setOtherTempInput(input: string): void;
  readonly otherInputRef: MutableRefObject<string>;
  setPostureDuringBP(posture: string): void;
  readonly postureDuringBP: string;
}

export const BloodPressureSection: React.FC<BloodPressureSectionProps> = ({
  currentPage,
  bloodPressureTimeTaken,
  otherTempInput,
  setOtherTempInput,
  otherInputRef,
  setPostureDuringBP,
  postureDuringBP
}) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  const [systolicBPTop, setSystolicBPTop] = useState(
    localStorage.getItem(`report${bloodPressureTimeTaken}SystolicBPTop`) || ""
  );

  const [diastolicBPBottom, setDiastolicBPBottom] = useState(
    localStorage.getItem(`report${bloodPressureTimeTaken}DiastolicBPBottom`) ||
      ""
  );
  const [timeStamp, setTimeStamp] = useState("");
  const [date, setDate] = useState<any>();

  useEffect(() => {
    localStorage.setItem("timeStamp", timeStamp);
    localStorage.setItem("timeStampDate", date);

    const newDate = moment(date);
    if (timeStamp === "AM") {
      localStorage.setItem(
        "dateYourBloodPressureWastaken",
        new Date(
          newDate.set({ hour: 9, minute: 0, second: 0 }).toString()
        ).toISOString()
      );
    } else {
      localStorage.setItem(
        "dateYourBloodPressureWastaken",
        new Date(
          newDate.set({ hour: 18, minute: 0, second: 0 }).toString()
        ).toISOString()
      );
    }
  }, [timeStamp]);

  const checkSystolicTop = (_: any, value: number) => {
    if (value) {
      if (value <= 220 && value >= 75) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(
          "Reading appears out of range. Please recheck and, if confirmed, call your doctor or go to ER immediately, especially if you have any symptoms."
        )
      );
    }
    return Promise.reject(new Error("This field is required"));
  };

  const checkDiastolicBottom = (_: any, value: number) => {
    if (value) {
      if (value <= 120 && value >= 20) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(
          "Reading appears out of range. Please recheck and, if confirmed, call your doctor or go to ER immediately, especially if you have any symptoms."
        )
      );
    }
    return Promise.reject(new Error("This field is required"));
  };

  const validateReportOtherSymptoms = (
    rules: RuleObject,
    value: string,
    callback: (error?: string) => void
  ) => {
    if (
      otherInputRef.current === "" &&
      localStorage.getItem("reportOtherSymptoms")?.includes("Other")
    ) {
      callback("Please enter value for 'Other' field.");
    } else {
      callback();
    }
  };

  return (
    <>
      {currentPage === 1 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                fontWeight: "600"
              }}
            >
              Please do not rely upon this survey for immediate medical
              attention. This survey may not be reviewed by your care team
              immediately. Please call your doctor for any medical problems. IF
              YOU THINK THIS IS A MEDICAL EMERGENCY, OR YOUR SYMPTOMS ARE
              WORRISOME PLEASE CALL 911 OR GO TO THE NEAREST EMERGENCY ROOM.
            </Space>
          </AppCard>

          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                fontWeight: "600"
              }}
            >
              <NephrologyLogo />
            </Space>
          </AppCard>
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
            >
              <Space
                direction="vertical"
                size={20}
                style={{ width: "100%", padding: "10px" }}
              >
                <CardTitle>
                  Do you want to report your blood pressure?
                  <AsteriskStyle> *</AsteriskStyle>
                </CardTitle>
                <FormItemStyle
                  name="Report your blood pressure"
                  label=""
                  rules={[
                    {
                      required: true
                    }
                  ]}
                  initialValue={""}
                >
                  <Radio.Group
                    value={localStorage.getItem("reportYourBloodPressure")}
                    onChange={e =>
                      localStorage.setItem(
                        "reportYourBloodPressure",
                        e.target.value
                      )
                    }
                  >
                    <Space direction="vertical">
                      <Radio value={"Yes"}>Yes</Radio>
                      <Radio value={"No"}>No</Radio>
                    </Space>
                  </Radio.Group>
                </FormItemStyle>
              </Space>
            </AppCard>
          </Space>
        </Space>
      )}
      {currentPage === 2 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <DisclaimerTitle>Blood Pressure</DisclaimerTitle>
              Discuss your target blood pressure with your doctor. PLEASE CALL
              YOUR DOCTOR, OR CALL 911 AND GO TO NEAREST ER FOR ANY SERIOUS
              CHANGE IN YOUR VITAL SIGNS OUTSIDE YOUR USUAL RECOMMENDED RANGE,
              OR IF YOU HAVE ANY ASSOCIATED SYMPTOMS (such as: Chest Pain,
              Shortness of Breath, Headache, Weakness, Dizzyness, Altered
              Vision, Confusion or other neurologic symptoms)
            </Space>
          </AppCard>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <CardTitle>
                When did you take your blood pressure?
                <AsteriskStyle> *</AsteriskStyle>
              </CardTitle>
              <FormItemStyle
                name="When was blood pressure taken"
                label=""
                rules={[
                  {
                    required: true
                  }
                ]}
                initialValue={""}
              >
                <Radio.Group
                  value={localStorage.getItem("whenWasBloodPressureTaken")}
                  onChange={e =>
                    localStorage.setItem(
                      "whenWasBloodPressureTaken",
                      e.target.value
                    )
                  }
                >
                  <Space direction="vertical">
                    <Radio value={"Taken today"}>Taken today</Radio>
                    <Radio value={"Taken before today"}>
                      Taken before today
                    </Radio>
                  </Space>
                </Radio.Group>
              </FormItemStyle>
            </Space>
          </AppCard>
        </Space>
      )}
      {currentPage === 3 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <DisclaimerTitle>Blood Pressure</DisclaimerTitle>
              <CardTitle>
                When was your blood pressure taken?
                <AsteriskStyle> *</AsteriskStyle>
              </CardTitle>
              <FormItemStyle
                name="When was your blood pressure taken(Date)"
                label=""
                rules={[
                  {
                    required: true
                  }
                ]}
                initialValue={""}
              >
                <AppDatePicker
                  label="Blood Pressure Taken on"
                  value={moment(
                    localStorage.getItem("dateYourBloodPressureWastaken")
                  )}
                  onChange={e => {
                    if (e) {
                      const newDate = moment(e);
                      setDate(newDate);
                    }
                  }}
                  style={{ width: "250px" }}
                  disabledDate={disableFutureDate}
                />
              </FormItemStyle>
            </Space>
          </AppCard>
        </Space>
      )}
      {currentPage === 4 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <DisclaimerTitle>Blood Pressure</DisclaimerTitle>
              <CardTitle>
                What time was your blood pressure taken?
                <AsteriskStyle> *</AsteriskStyle>
              </CardTitle>
              <FormItemStyle
                name="What time was blood pressure taken"
                label=""
                rules={[
                  {
                    required: true
                  }
                ]}
                initialValue={""}
              >
                <Radio.Group
                  value={localStorage.getItem("whatTimeWasBloodPressureTaken")}
                  onChange={e => {
                    setTimeStamp(e.target.value);
                    localStorage.setItem(
                      "whatTimeWasBloodPressureTaken",
                      e.target.value
                    );
                  }}
                >
                  <Space direction="vertical">
                    <Radio value={"AM"}>AM</Radio>
                    <Radio value={"PM"}>PM</Radio>
                  </Space>
                </Radio.Group>
              </FormItemStyle>
            </Space>
          </AppCard>
        </Space>
      )}
      {currentPage === 5 && (
        <Space direction="vertical" size={20}>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <DisclaimerTitle>
                {bloodPressureTimeTaken} Blood Pressure
              </DisclaimerTitle>
              Discuss your target blood pressure with your doctor. PLEASE CALL
              YOUR DOCTOR, OR CALL 911 AND GO TO NEAREST ER FOR ANY SERIOUS
              CHANGE IN YOUR VITAL SIGNS OUTSIDE YOUR USUAL RECOMMENDED RANGE,
              OR IF YOU HAVE ANY ASSOCIATED SYMPTOMS (such as: Chest Pain,
              Shortness of Breath, Headache, Weakness, Dizzyness, Altered
              Vision, Confusion or other neurologic symptoms)
            </Space>
          </AppCard>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <CardTitle>
                Report {bloodPressureTimeTaken} Systolic BP(Top Number)
                <AsteriskStyle> *</AsteriskStyle>
              </CardTitle>
              <FormItemStyle
                name={`Report ${bloodPressureTimeTaken} Systolic BP(Top Number)`}
                label=""
                rules={[
                  {
                    required: true,
                    validator: checkSystolicTop,
                    validateTrigger: "submit"
                  }
                ]}
                initialValue={""}
              >
                <SurveyNumberInput
                  value={systolicBPTop}
                  placeholder="Your Answer"
                  decimalLimit={0}
                  id={`lightheadedness_Report ${bloodPressureTimeTaken} Systolic BP(Top Number)`}
                  onChange={e => {
                    setSystolicBPTop(e as string);
                    localStorage.setItem(
                      `report${bloodPressureTimeTaken}SystolicBPTop`,
                      e as string
                    );
                  }}
                  min={0}
                  style={{ width: "120px" }}
                />
              </FormItemStyle>
            </Space>
          </AppCard>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <CardTitle>
                Report {bloodPressureTimeTaken} Diastolic BP(Bottom Number)
                <AsteriskStyle> *</AsteriskStyle>
              </CardTitle>
              <FormItemStyle
                name={`Report ${bloodPressureTimeTaken} Diastolic BP(Bottom Number)`}
                label=""
                rules={[
                  {
                    required: true,
                    validator: checkDiastolicBottom,
                    validateTrigger: "submit"
                  }
                ]}
                initialValue={""}
              >
                <SurveyNumberInput
                  value={diastolicBPBottom}
                  placeholder="Your Answer"
                  decimalLimit={0}
                  id={`lightheadedness_Report ${bloodPressureTimeTaken} Diastolic BP(Bottom Number)`}
                  onChange={e => {
                    setDiastolicBPBottom(e as string);
                    localStorage.setItem(
                      `report${bloodPressureTimeTaken}DiastolicBPBottom`,
                      e as string
                    );
                  }}
                  min={0}
                  style={{ width: "120px" }}
                />
              </FormItemStyle>
            </Space>
          </AppCard>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <CardTitle>Posture during BP</CardTitle>
              <FormItemStyle
                name="Posture during BP"
                label=""
                initialValue={""}
              >
                <Radio.Group
                  value={postureDuringBP}
                  onChange={e => {
                    localStorage.setItem("postureDuringBP", e.target.value);
                    setPostureDuringBP(e.target.value);
                  }}
                >
                  <Space direction="vertical">
                    <Radio value={"Sitting"}>Sitting</Radio>
                    <Radio value={"Standing"}>Standing</Radio>
                    <Radio value={"Lying"}>Lying</Radio>
                  </Space>
                </Radio.Group>
                <ClearSelectionContainer>
                  <AppButton
                    buttonContent={"Clear Selection"}
                    type={ButtonType.Link}
                    onClick={() => {
                      setPostureDuringBP("");
                      localStorage.setItem("postureDuringBP", "");
                    }}
                  />
                </ClearSelectionContainer>
              </FormItemStyle>
            </Space>
          </AppCard>
          <AppCard
            cardHeight="fit-content"
            cardWidth={
              platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
            }
          >
            <Space
              direction="vertical"
              size={20}
              style={{ width: "100%", padding: "10px" }}
            >
              <CardTitle>
                Report other symptoms or comments with BP reading
              </CardTitle>
              <FormItemStyle
                name="Report other symptoms"
                label=""
                initialValue={""}
                rules={[
                  {
                    validator: validateReportOtherSymptoms,
                    validateTrigger: "submit"
                  }
                ]}
              >
                <Checkbox.Group
                  style={{ width: "100%" }}
                  onChange={e =>
                    localStorage.setItem("reportOtherSymptoms", e.toString())
                  }
                  value={sessionStorage
                    .getItem("reportOtherSymptoms")
                    ?.split(/,/)}
                >
                  <Row gutter={[10, 10]}>
                    <Col span={24}>
                      <Checkbox value="Dizzyness or Lightheadedness">
                        Dizzyness or Lightheadedness
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="Weakness or Fatigue">
                        Weakness or Fatigue
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value="Other">Other</Checkbox>
                      <SurveyInput
                        value={otherTempInput ?? otherInputRef}
                        placeholder={""}
                        onChange={e => {
                          setOtherTempInput(e.target.value);
                          otherInputRef.current = e.target.value;
                        }}
                        style={{ width: "70%", marginLeft: "30px" }}
                      />
                    </Col>
                  </Row>
                </Checkbox.Group>
              </FormItemStyle>
            </Space>
          </AppCard>
        </Space>
      )}
    </>
  );
};

const ClearSelectionContainer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
`;

const DisclaimerTitle = styled.div`
  width: 100%;
  padding: 8px 8px 8px 20px;
  margin-left: -25px;
  background: ${Colors.DodgerBlue};
  font-size: 18px;
  font-family: sans-serif;
  border-radius: 8px 8px 0px 0px;
  color: white;
  position: absolute;
  top: 0;
`;

const FormItemStyle = styled(Form.Item)`
  margin-bottom: 0px;
`;

const AsteriskStyle = styled.span`
  font-weight: 800;
  color: red;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-family: sans-serif;
`;
