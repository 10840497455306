import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Spin from "antd/lib/spin";
import React, { useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { CommonErrorMessage } from "components/CommonErrorMessage";
import { AppSelect } from "components/inputs";
import { SearchBox } from "components/searchBox";
import { Colors } from "helpers/colors";
import { DiagnosisCheckboxDropdown } from "./diagnosisCheckboxDropdown";
import {
  PatientListContext,
  PatientListContextState
} from "contextApis/patientsListContext";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";

type Patient = "All Patients" | "Remote Monitoring" | "Others";

interface ExtensionItemsProps {
  allDiagnosisChecked: boolean;
  setAllDiagnosisChecked(checked: boolean): void;
  applied: boolean;
  setApplied(checked: boolean): void;
  setPatientType(item: Patient): void;
  patientType: Patient;
  setSearchValue(value: string): void;
  diagnosisSelected:
    | {
        desc: string;
        code: string;
        detail_desc: string;
        checked?: boolean;
      }[]
    | [];
  isLoadingDiagnosesList: boolean;
  isDiagnosesListError: unknown;
  setVBCPlan(item: any): void;
  setVBCPlanName(item: any): void;
  vbcPlanName: any;
  vbcList: any;
  setSelectedDiagnosesItemCodes(item: any): void;
}

export const ExtensionItems: React.FC<ExtensionItemsProps> = ({
  allDiagnosisChecked,
  setAllDiagnosisChecked,
  applied,
  setApplied,
  setPatientType,
  patientType,
  setSearchValue,
  diagnosisSelected,
  isLoadingDiagnosesList,
  isDiagnosesListError,
  setVBCPlan,
  setVBCPlanName,
  vbcPlanName,
  vbcList,
  setSelectedDiagnosesItemCodes
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const patientListContext =
    useContext<PatientListContextState>(PatientListContext);

  const memoisedMenuOneItems = useMemo(
    () => patientListContext.diagnosesMenuOne,
    [patientListContext.diagnosesMenuOne]
  );

  const memoisedMenuTwoItems = useMemo(
    () => patientListContext.diagnosesMenuTwo,
    [patientListContext.diagnosesMenuTwo]
  );

  const onTypeChange = (type: Patient) => {
    setPatientType(type);
    sessionStorage.setItem("patientType", type);
  };

  let vbcOptions: any = [];

  const onVBCTypeChange = (type: any) => {
    setVBCPlan(type);
    const planName = vbcOptions.find((option: any) => option.value === type);
    setVBCPlanName(planName.label);
    sessionStorage.setItem("vbcPlanType", type);
    sessionStorage.setItem("vbcPlanName", planName.label);
  };

  const patientOptions: {
    label: string;
    value: Patient;
    disabled?: boolean;
  }[] = [
    {
      label: "My Patients",
      value: "All Patients"
    },
    {
      label: "Remote Monitoring",
      value: "Remote Monitoring"
    },
    {
      label: "Others",
      value: "Others",
      disabled: true
    }
  ];

  if (vbcList != "") {
    vbcOptions = vbcList?.map((item: any) => ({
      label: item?.name,
      value: item?.id
    }));
    vbcOptions.unshift({
      label: "Any",
      value: "-1"
    });
  }

  const selectedDiagnosisDisplayItems = diagnosisSelected.filter(
    (obj: {
      desc: string;
      code: string;
      detail_desc: string;
      checked?: boolean;
    }) => {
      if (obj.checked) {
        return obj.desc;
      }
      return null;
    }
  );

  const selectedDiagnosisDisplayText = selectedDiagnosisDisplayItems.map(
    (obj: {
      desc: string;
      code: string;
      detail_desc: string;
      checked?: boolean;
    }) => obj.desc
  );

  function resetFilters() {
    sessionStorage.removeItem("patientType");
    sessionStorage.removeItem("vbcPlanType");
    sessionStorage.removeItem("vbcPlanName");
    sessionStorage.removeItem("searchText");
    sessionStorage.removeItem("diagonose");
    setPatientType("All Patients");
    setSearchValue("");
    setVBCPlan("Any");
    setVBCPlanName("Any");
    setSelectedDiagnosesItemCodes("");
    const temp = diagnosisSelected.map((item: any) => {
      item.checked = false;
      return item;
    });
    patientListContext.setDiagnosesMenuOne(temp);
  }

  return (
    <RowStyled>
      <TitleCol span={3}>My Patients</TitleCol>
      <FiltersCol span={18}>
        <Space>
          <SearchBox
            placeholder="Search by name"
            onChange={e => {
              setSearchValue(e.target.value);
              sessionStorage.setItem("searchText", e.target.value);
            }}
            value={
              !sessionStorage.getItem("searchText") ||
              sessionStorage.getItem("searchText")?.length == 0
                ? ""
                : String(sessionStorage.getItem("searchText"))
            }
          />
          <AppSelect
            value={patientType}
            label="Show"
            onChange={onTypeChange}
            style={{ marginRight: 5, minWidth: "196px" }}
            options={patientOptions}
            dropdownStyle={{ borderRadius: "8px" }}
          />

          {isLoadingDiagnosesList ? (
            <SpinErrorContainer>
              <Spin />
            </SpinErrorContainer>
          ) : isDiagnosesListError ? (
            <CommonErrorMessage message="Error fetching diagnosis list" />
          ) : (
            <DiagnosisCheckboxDropdown
              menuOneItems={memoisedMenuOneItems}
              menuTwoItems={memoisedMenuTwoItems}
              setShowDropdown={setShowDropdown}
              showDropdown={showDropdown}
              selectedDiagnosisDisplayText={selectedDiagnosisDisplayText.join(
                ", "
              )}
              allDiagnosisChecked={allDiagnosisChecked}
              setAllDiagnosisChecked={setAllDiagnosisChecked}
              applied={applied}
              setApplied={setApplied}
            />
          )}

          <AppSelect
            value={vbcPlanName}
            label="VBC Plan"
            onChange={onVBCTypeChange}
            style={{ marginRight: 5, minWidth: "196px", whiteSpace: "nowrap" }}
            options={vbcOptions}
            dropdownStyle={{ borderRadius: "8px" }}
          />
        </Space>
      </FiltersCol>
      <ButtonsCol span={3}>
        <AppButton
          buttonContent="Reset"
          type={ButtonType.Secondary}
          style={{ boxShadow: "none" }}
          onClick={() => resetFilters()}
        />
      </ButtonsCol>
    </RowStyled>
  );
};

const SpinErrorContainer = styled.div`
  padding: 5px;
  padding-top: 15px;
  color: ${Colors.Red};
`;

const TitleCol = styled(Col)`
  font-family: Century Gothic Bold;
  padding: 20px 0px 20px 10px;
`;

const FiltersCol = styled(Col)`
  padding: 10px 0px;
`;

const ButtonsCol = styled(Col)`
  text-align: center;
  padding: 10px 0px;
`;

// const ButtonText = styled.span`
//   margin-left: 5px;
// `;

const RowStyled = styled(Row)`
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
`;
