import React, { useEffect, useState } from "react";
import Tabs from "antd/lib/tabs";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Colors } from "helpers/colors";
import {
  CaregiverRoutesRef,
  PatientsRoutesRef,
  ProvidersRoutesRef
} from "constants/routes";
import { UserRoles } from "constants/roles";

const { TabPane } = Tabs;

interface ParentTabsProps {
  readonly tabsContent: {
    key: string;
    tabName: string;
    onClickRedirectPath: string;
  }[];
  readonly defaultActiveKey: string;
}

export const ParentTabsComponent: React.FC<ParentTabsProps> = ({
  defaultActiveKey,
  tabsContent
}) => {
  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;

  const relevantRoutesRef = userIsCaregiver
    ? CaregiverRoutesRef
    : PatientsRoutesRef;

  const [currentTab, setCurrentTab] = useState<string>(
    ProvidersRoutesRef.Patients
  );
  const navigate = useNavigate();
  const location = useLocation();

  const patientId = sessionStorage.getItem("patientId");

  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  useEffect(() => {
    const currentPath = tabsContent.filter(item => {
      return location.pathname.includes(item.key);
    });

    if (location.pathname === "/") {
      if (userIsPatient) {
        navigate(relevantRoutesRef.MyMedicalData);
      } else {
        navigate(ProvidersRoutesRef.Patients);
      }
    } else {
      setCurrentTab(currentPath[0] ? currentPath[0].key : "");
    }

    if (location.pathname === "/" + relevantRoutesRef.MyHealthStatus) {
      navigate(relevantRoutesRef.MyCurrentStatus);
    }

    if (location.pathname === "/" + relevantRoutesRef.MyMedicalData) {
      navigate(relevantRoutesRef.Symptoms);
    }
  }, [navigate]);

  const setURLActiveTab = (activeKey: string) => {
    navigate(`/${activeKey}`);
  };

  const hideInkBar = () => {
    if (
      (window.location.pathname.includes(ProvidersRoutesRef.Patients) ||
        window.location.pathname.includes(ProvidersRoutesRef.Providers) ||
        window.location.pathname.includes(ProvidersRoutesRef.Messages) ||
        window.location.pathname.includes(ProvidersRoutesRef.Dashboard) ||
        window.location.pathname.includes(ProvidersRoutesRef.Billings) ||
        window.location.pathname.includes(relevantRoutesRef.MyHealthStatus) ||
        window.location.pathname.includes(relevantRoutesRef.MyMedicalData) ||
        window.location.pathname.includes(relevantRoutesRef.MyCareTeam)) &&
      !window.location.pathname.includes(relevantRoutesRef.AllMessages)
    ) {
      return "false";
    }
    return "true";
  };

  return (
    <ParentTabsStyled
      defaultActiveKey={defaultActiveKey}
      hideinkbar={hideInkBar()}
      onChange={activeKey => {
        setURLActiveTab(activeKey);
        setCurrentTab(activeKey);

        const selectedTab = tabsContent.filter(item => {
          return item.key === activeKey;
        });

        if (userIsCaregiver) {
          navigate(selectedTab[0].onClickRedirectPath + "/" + patientId);
        } else {
          navigate(selectedTab[0].onClickRedirectPath);
        }
      }}
      centered
      activeKey={currentTab}
      onTabClick={key => {
        const selectedTab = tabsContent.filter(item => {
          return item.key === key;
        });
        if (userIsCaregiver) {
          navigate(selectedTab[0].onClickRedirectPath + "/" + patientId);
        } else {
          navigate(selectedTab[0].onClickRedirectPath);
        }
      }}
    >
      {tabsContent.map(tab => {
        return (
          <TabPane
            tab={tab.tabName}
            key={tab.key}
            style={{
              height: "0px"
            }}
          ></TabPane>
        );
      })}
    </ParentTabsStyled>
  );
};

interface CommonTabProps {
  hideinkbar: string;
}

const CommonTabStyles = styled(Tabs)<CommonTabProps>`
  margin-left: 7px;
  font-size: 14px;
  //Tab button style
  .ant-tabs-tab-btn {
    margin-top: -2px;
    margin-bottom: 5.5px;
    color: ${Colors.gothicBold};
    z-index: 10;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${Colors.gothicBold};
    font-family: Century Gothic Bold;
  }
  .ant-tabs-tab {
    margin-right: 12px;
  }

  //Animated bar style
  .ant-tabs-ink-bar {
    padding: 2.5px;
    border-radius: 5px;
    box-shadow: 0 8px 6px -6px #999;
    background-color: ${Colors.DodgerBlue};
    display: ${props => (props.hideinkbar === "true" ? "none" : "")};
  }
`;

const ParentTabsStyled = styled(CommonTabStyles)`
  // Nav section style
  .ant-tabs-nav {
    margin: 0px 0px 0px 0px;
  }

  .ant-tabs-nav::before {
    /* For the line to the right and close to the tabs */
    border-color: transparent;
  }

  position: absolute;
  left: 0px;
  width: 100%;
  top: 9px;
  z-index: 1000;
`;
