import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { UserContext, UserContextState } from "contextApis/userContext";
import styled from "styled-components";

interface RpmConsentFormProps {
  pName: string;
  pDob: string | undefined;
  sign: string | undefined;
  // relative: string | null;
  pEmail: string | undefined;
  signer: string | null;
  clientLogo: string | null;
  consentName: string | null;
  orgName: string | null;
  deviceId: string | null;
  userInitial: string;
}

const RpmConsentForm: React.FC<RpmConsentFormProps> = ({
  pName,
  pDob,
  sign,
  // relative,
  pEmail,
  signer,
  clientLogo,
  consentName,
  orgName,
  deviceId,
  userInitial
}) => {
  const userContext = useContext<UserContextState>(UserContext);

  const [patientName, SetPatientName] = useState(pName);
  const [patientDob, SetPatientDob] = useState(pDob);
  const [patientRealatedPersonName, setPatientRealatedPersonName] =
    useState(signer);
  const [patientInitial, setPatientInitial] = useState(userInitial);
  // const [relation, setRelation] = useState<string | null>(relative);
  const [patientEmail, SetPatientEmail] = useState(pEmail);

  useEffect(() => {
    setPatientInitial(userInitial);
  }, [userInitial]);

  useEffect(() => {
    // setRelation(relative);
    setPatientRealatedPersonName(signer);
  }, [signer]);

  const date = new Date();

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const formattedTodaysDate = `${day}/${month}/${year}`;

  useEffect(() => {
    if (sign) {
      userContext.setPdfByteString(sign);
    } else {
      userContext.setPdfByteString(patientInitial);
    }
    consentName && userContext.setConsentName(consentName);
    patientName && userContext.setSignerName(patientName);
    patientRealatedPersonName &&
      userContext.setSignerRelationship(patientRealatedPersonName);
  }, [patientRealatedPersonName, sign, patientInitial]);

  return (
    <div className="consesntContainer">
      <div className="form-container" id="divToExport">
        <div className="header">
          <img
            className="logo-left"
            src={process.env["REACT_APP_ASSET_ENDPOINT"] + "" + clientLogo}
            alt="NephrologySpeaciallistsLogo"
            width={300}
          />
          <img
            className="logo-right"
            src={
              process.env["REACT_APP_ASSET_ENDPOINT"] +
              "logo/CareGemHealthLogo.png"
            }
            alt="CareGemHealthLogo"
            width={180}
          />
        </div>
        <div style={{ marginTop: 40 }}>
          <p style={{ fontWeight: 650, fontSize: 16 }}>
            Remote Patient Monitoring (RPM) Consent Form
          </p>
          <p className="paragraph">
            For (Patient): <span className="patientData">{patientName}</span>{" "}
            &nbsp;DOB:<span className="patientData">{patientDob}</span>
          </p>
          <p className="paragraph">
            Device ID #:{" "}
            {deviceId ? (
              <span className="patientData">{deviceId}</span>
            ) : (
              <span>As Assigned</span>
            )}
          </p>
          <p className="paragraph">
            I, <span className="patientData">{patientName}</span>&nbsp;(Patient/
            Power of Attorney /Guardian / Responsible party), agree that:
          </p>
          <p className="paragraph">
            &bull; <span className="patientData">{patientName}</span>{" "}
            &nbsp;(‘Patient’) will be the only person who will use the remote
            monitoring device (‘Device’) for their personal health monitoring. I
            will use the Device as per instructions provided. I will not use the
            Device for reasons other than personal health monitoring, or for any
            other individual. I understand that use of device for any other
            individual will cause the other individual’s data to intermingle
            with Patient’s data and may lead to incorrect management.
          </p>
          <p className="paragraph">
            &bull; I understand that I can only participate in this program with
            one Medical Provider at a time.
          </p>
          <p className="paragraph">
            &bull; I understand the Device is only designed for the {orgName}{" "}
            RPM program, and I consent to participate in this program. I can
            withdraw my consent to participate in this program, and revoke
            service at any time by returning the Device.
          </p>
          <p className="paragraph">
            &bull; I acknowledge that I received Blood Pressure Device ID #:
            {deviceId ? deviceId : " As Assigned"}
          </p>
          <p className="paragraph">
            &bull; I understand that Device will collect Patient’s Blood
            Pressure Readings and transmit those readings to an online platform
            in a safe and secure manner.
          </p>
          <p className="paragraph">
            &bull; I UNDERSTAND THAT THE DEVICE IS NOT AN EMERGENCY RESPONSE
            UNIT. DEVICE READINGS ARE NOT MONITORED 24/7, AND ARE REVIEWED
            INTERMITTENTLY ONLY. I WILL CALL 911 FOR IMMEDIATE MEDICAL
            EMERGENCIES.
          </p>
          <p className="paragraph">
            &bull; I will do my best to take my BP every day. I am aware that
            staff will review the remote monitoring data intermittently every 30
            days, and that this program is NOT a 24/7 Monitoring Service. I will
            be contacted by remote monitoring staff by phone or video conference
            to review and discuss my results and progress.
          </p>
          <p className="paragraph">
            &bull; I understand that {orgName} is the owner of the device and
            Device is given to me as a loan. I will not tamper with or misuse
            the Device.
          </p>
          <p className="paragraph">
            &bull; I understand may terminate this Remote Monitoring Service at
            any time, and I agree to return the Device to {orgName} upon
            termination of service.
          </p>
          <p className="paragraph">
            &bull;I understand that my insurance will be billed for remote
            monitoring services and copays may apply, for which I may be
            responsible.
          </p>
          <p className="paragraph">
            I have read and understood the information and consent to
            participate in the Remote Patient Monitoring program as stated
            above. I am aware that this consent is valid as long as I’m in
            possession of the RPM equipment/device.
          </p>
          <p className="paragraph">
            Date:
            <span className="patientData" style={{ marginLeft: 18 }}>
              {formattedTodaysDate}
            </span>
            &nbsp;(dd/mm/yyyy)
          </p>
          <p className="paragraph">
            Signature of Patient :
            <span className="patientData" style={{ marginLeft: 18 }}>
              {!sign ? (
                <>{userInitial}</>
              ) : (
                <img
                  src={sign}
                  width={70}
                  height={35}
                  style={{ marginBottom: 25 }}
                  alt=""
                />
              )}
            </span>
          </p>
          {/* <StyledParagraphContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                alignItems: "start",
                gap: 0
              }}
            >
              <p className="paragraph" style={{ margin: 0 }}>
                Signature of Authorized Person
              </p>
              <p className="paragraph" style={{ margin: 0 }}>
                (POA or other responsible party):
              </p>
            </div>
            <span
              className="patientData"
              style={{ marginLeft: 18, marginBottom: 0, alignSelf: "flex-end" }}
            ></span>
          </StyledParagraphContainer> */}
          <p className="paragraph" style={{ marginTop: sign ? -20 : "" }}>
            Patient Email address:
            <span className="patientData" style={{ marginLeft: 18 }}>
              {patientEmail}
            </span>
          </p>
          <p className="paragraph">
            (Relationship of Authorized Person)
            <span className="patientData" style={{ marginLeft: 18 }}>
              {patientRealatedPersonName}
            </span>
          </p>
          <p className="paragraph">
            Consent taken digitally by CareGem on behalf of{" "}
            {orgName?.toUpperCase()}
          </p>
        </div>
      </div>
    </div>
  );
};

const StyledParagraphContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
`;

export default RpmConsentForm;
