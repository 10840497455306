import React, { useState } from "react";

import { AppTabs } from "components/tabs";
import { MyKidneyFunction } from "./myKidneyFunction";
import { MyHeartHealth } from "./myHeartHealth";
import { MyBloodPressure } from "./myBloodPressure";
import { CommonPatientExtensionItems } from "pages/patients/commonPatientExtensionItems";
import { UserRoles } from "constants/roles";

enum CurrentStatusTypes {
  MyKidneyFunction = "my-kidney-function",
  MyBloodPressure = "my-blood-pressure",
  MyHeartHealth = "my-heart-health"
}

export const MyCurrentStatusPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<CurrentStatusTypes>(
    CurrentStatusTypes.MyKidneyFunction
  );

  const getSelectedTab = (key: CurrentStatusTypes) => {
    setSelectedTab(key);
  };

  const tabsContent = [
    {
      key: CurrentStatusTypes.MyKidneyFunction,
      tabName: "My Kidney Function"
    },
    {
      key: CurrentStatusTypes.MyBloodPressure,
      tabName: "My Blood Pressure"
    },
    {
      key: CurrentStatusTypes.MyHeartHealth,
      tabName: "My Heart Health"
    }
  ];

  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;

  const showTopbarExtesion = userIsCaregiver;

  return (
    <div style={{ position: "relative", height: "80vh" }}>
      {showTopbarExtesion && <CommonPatientExtensionItems />}
      <div>
        <AppTabs
          defaultActiveKey={selectedTab}
          tabsContent={tabsContent}
          tabPosition="top"
          getSelectedTab={getSelectedTab}
        />
      </div>

      <>
        {selectedTab === CurrentStatusTypes.MyKidneyFunction && (
          <MyKidneyFunction />
        )}
        {selectedTab === CurrentStatusTypes.MyBloodPressure && (
          <MyBloodPressure />
        )}
        {selectedTab === CurrentStatusTypes.MyHeartHealth && <MyHeartHealth />}
      </>
    </div>
  );
};
