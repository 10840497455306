import React, { useEffect, useState } from "react";
import Menu from "antd/lib/menu";
import Checkbox from "antd/lib/checkbox";
import Space from "antd/lib/space";
import styled from "styled-components";
import { Colors } from "helpers/colors";
import { CheckboxMenuCommonInterface } from "sharedTypes";

interface CheckboxMenuProps {
  readonly applied?: boolean;
  readonly setApplied: (applied: boolean) => void;
  readonly currentItems: CheckboxMenuCommonInterface[];
  readonly setFinalItems: (checkedItems: CheckboxMenuCommonInterface[]) => void;
  readonly isDiagnoses?: boolean;
}

export const BillCheckboxMenu: React.FC<CheckboxMenuProps> = ({
  applied,
  setApplied,
  isDiagnoses,
  setFinalItems,
  currentItems
}) => {
  const [checkedItems, setCheckedItems] =
    useState<CheckboxMenuCommonInterface[]>(currentItems);

  useEffect(() => {
    if (applied) {
      setFinalItems(checkedItems);
      setApplied(false);
    }
  }, [applied]);

  const onSelectMenuItem = (item: string) => {
    setApplied(false);

    const checkedTemp = [...checkedItems];

    const objIndex = checkedItems.findIndex(obj => obj.code === item);

    checkedTemp[objIndex].checked = !checkedTemp[objIndex].checked;

    setCheckedItems(checkedTemp);
  };

  return (
    <MenuStyled>
      {checkedItems.map(({ code, desc, checked }, index) => {
        return (
          <Menu.Item
            key={index}
            onClick={() => {
              onSelectMenuItem(code);
            }}
          >
            <Space size={10}>
              <CheckboxStyled checked={checked} />
              {checked ? (
                <>
                  {isDiagnoses ? (
                    <>
                      <b>
                        {desc} ({code})
                      </b>
                    </>
                  ) : (
                    <b>{`${code} - ${desc}`}</b>
                  )}
                </>
              ) : (
                <>
                  {isDiagnoses ? (
                    <>
                      {desc}
                      {`(${code})`}
                    </>
                  ) : (
                    `${code} - ${desc}`
                  )}
                </>
              )}
            </Space>
          </Menu.Item>
        );
      })}
    </MenuStyled>
  );
};

const CheckboxStyled = styled(Checkbox)`
  pointer-events: none;
`;

const MenuStyled = styled(Menu)`
  width: fit-content;
  height: 174px;
  overflow: scroll;
  padding: 15px 6px 12px 6px;
  color: ${Colors.Grey};
  border-right: none;
  background-color: ${Colors.GhostWhite};

  .ant-menu-item {
    margin-top: -5px !important;
    :hover {
      background-color: ${Colors.BabyBlue};
      border-radius: 10px;
      transition: 0s;
      color: ${Colors.Black};
    }
  }

  .ant-menu-item-selected {
    background-color: ${Colors.BabyBlue} !important;
    border-radius: 10px;
    transition: 0s;
    color: ${Colors.Black};
  }
`;
