import React, { useContext, useEffect } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

import styled from "styled-components";
import { AppAvatar } from "components/avatar";
import { AppCard } from "components/card";
import { CircleIndicator } from "components/circleIndicator";
import { Colors } from "helpers/colors";
import { useNavigate } from "react-router-dom";
import { CaregiverRoutesRef, ProvidersRoutesRef } from "constants/routes";
import Table, { ColumnsType } from "antd/lib/table";
import { FilterDisplayComponent } from "components/filterDisplay/filterDisplay";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { CommonErrorMessage } from "components/CommonErrorMessage";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { DateTimeFormat } from "helpers/dateTimeFormat";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { UserRoles } from "constants/roles";
import { AllPatientsList, CheckboxMenuCommonInterface } from "sharedTypes";
import { Result } from "antd";
import { CareGemLogo } from "./careGemLogo";
import RemoteMonitoringIcon from "./RemoteMonitoringIcon";

type Patient = "All Patients" | "Remote Monitoring" | "Others";

interface TableRow {
  readonly id: number;
  readonly first_name: string;
  readonly last_name: string;
  readonly age: number;
  readonly visit_date: string;
  readonly notification_level: string;
  readonly tasks: string;
  readonly picture: string;
  readonly dob: string;
  readonly name: string;
  readonly internal_id: string;
  readonly appointment_id: string;
  readonly remote_monitoring: string;
  readonly username: string;
  readonly phoneNumbers: {
    title: string;
    number: string;
  }[];
  readonly vbc: string;
}

interface AllPatientsTableProps {
  readonly patientType: Patient;
  readonly searchValue: string;
  readonly isLoadingDiagnosesList: boolean;
  readonly menuOneSelectedList: CheckboxMenuCommonInterface[];
  readonly menuTwoSelectedList: CheckboxMenuCommonInterface[];
  diagnosesFilterApplied: boolean;
  readonly setSelectedDiagnosesItemCodes: (value: string) => void;
  readonly isLoadingPatientList: boolean;
  readonly patientData: AllPatientsList[] | undefined;
  readonly isPatientListError: Error | null;
  readonly getPatientList: () => void;
  readonly isPatientListRefetchLoading: boolean;
  readonly vbcPlanName: any;
  readonly scrollContainerRef?: any;
}

export const AllPatientsTable: React.FC<AllPatientsTableProps> = ({
  patientType,
  searchValue,
  menuOneSelectedList,
  menuTwoSelectedList,
  diagnosesFilterApplied,
  setSelectedDiagnosesItemCodes,
  isLoadingPatientList,
  patientData,
  isPatientListError,
  getPatientList,
  isPatientListRefetchLoading,
  vbcPlanName,
  scrollContainerRef
}) => {
  const epicLogin: any = sessionStorage.getItem("epicLogin");

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    ) {
      console.log("sadaldlasd");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (epicLogin) {
    sessionStorage.setItem("hideTop", "true");
    return (
      <MainContainer>
        <div>
          <CareGemLogo />
        </div>
        <Divider />
        <Result
          status="403"
          title="Access Denied"
          subTitle="Sorry, you do not have accss to this page."
        />
      </MainContainer>
    );
  }

  const navigate = useNavigate();

  sessionStorage.setItem("surveyNotification", "");
  const patientContext = useContext<PatientContextState>(PatientContext);
  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const userId = sessionStorage.getItem("userId") || "";

  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;

  const { refetch: getNotificationList } = useQuery(
    "get-notifications",
    async () => {
      return await createApiClient().getNotifications(
        userId ? userId : "",
        userIsCaregiver ? UserRoles.CAREGIVER : UserRoles.PROVIDER
      );
    },
    {
      onSuccess: data => {
        notificationsContext.setNotifications(data ? data : null);
      },
      refetchInterval: 60000
    }
  );

  const {
    isLoading: isLoadingPatientSurveyNotification,
    data: patientSurveyNotification,
    error: isPatientSurveyNotificationError,
    isFetching: isFetchingPatientSurveyNotification,
    refetch: refetchPatientSurveyNotification
  } = useQuery(
    "PatientSurveyNotification",
    async () => {
      const data = await createApiClient().getPatientSurveyNotification(
        Number(sessionStorage.getItem("patientId"))
      );
      sessionStorage.setItem("surveyNotification", data);
      notificationsContext.setPatientSurveyNotifications(data ? data : null);
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (diagnosesFilterApplied) {
      const selectedItemsOne = menuOneSelectedList.map(
        (obj: {
          desc: string;
          code: string;
          detail_desc: string;
          checked?: boolean;
        }) => obj.code
      );

      const selectedItemsTwo = menuTwoSelectedList.map(
        (obj: {
          desc: string;
          code: string;
          detail_desc: string;
          checked?: boolean;
        }) => obj.code
      );

      const selectedDiagnosesCodesTemp =
        selectedItemsOne.concat(selectedItemsTwo);

      setSelectedDiagnosesItemCodes(selectedDiagnosesCodesTemp.join(","));
      setTimeout(() => getPatientList(), 500);
    }
  }, [diagnosesFilterApplied]);

  const compareNames = (a: any, b: any) => {
    return a.last_name === b.last_name
      ? a.first_name.localeCompare(b.first_name)
      : a.last_name.localeCompare(b.last_name);
  };

  const columns: ColumnsType<TableRow> = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (name: string, row: TableRow) => {
        return (
          <AppAvatar
            imageSrc={row.picture}
            size="large"
            mainContent={row.last_name + ", " + row.first_name}
            subText={`${row.age} years`}
            suffixIcon={<RemoteMonitoringIcon status={row.remote_monitoring} />}
          />
        );
      },
      width: "20%",
      sorter: (a, b) => compareNames(a, b),
      showSorterTooltip: { title: "" },
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "ascend"
    },
    {
      title: "Appointment Date",
      key: "visit_date",
      dataIndex: "visit_date",
      render: (visit_date: string) => (
        <DateRowStyle>
          <DateTimeFormat date={Date.parse(visit_date)} />
        </DateRowStyle>
      ),
      width: "20%"
    },
    {
      title: "Notifications",
      key: "notification_level",
      dataIndex: "notification_level",
      render: notification_level => {
        return (
          <div style={{ display: "grid", placeItems: "center", width: "60px" }}>
            <CircleIndicator
              outerColor={
                notification_level === 2
                  ? "#EEB5AD"
                  : notification_level === 1
                  ? "#fbddb7"
                  : "inherit"
              }
              innerColor={
                notification_level === 2
                  ? Colors.Red
                  : notification_level === 1
                  ? "#f18e13"
                  : "inherit"
              }
            />
          </div>
        );
      },
      width: "20%"
    },
    {
      title: "Tasks",
      key: "tasks",
      dataIndex: "tasks",
      render: () => {
        return (
          <Row gutter={[10, 0]}>
            <Col style={{ display: "grid", placeItems: "center" }}>
              {/* <Space>
                <CircleIndicator outerColor="#fabbb8" innerColor="#f22b1f" />
                Incomplete(Mocked)
              </Space> */}
            </Col>
          </Row>
        );
      },
      width: "20%"
    },
    {
      title: "Value Based Care Plan",
      key: "vbc",
      dataIndex: "vbc",
      width: "20%",
      render: (vbc: string) => {
        return <p style={{ fontWeight: "bold" }}>{vbc}</p>;
      }
    }
  ];

  const noAssociatedPatient =
    isPatientListError && JSON.parse(isPatientListError.message).status === 400;

  return (
    // <div ref={scrollContainerRef}>
    <AppCard cardHeight="inherit" cardWidth="100%">
      <FilterWrapper>
        <FilterDisplayComponent
          value1={searchValue}
          value2={patientType}
          value4={vbcPlanName}
          list1={menuOneSelectedList.map(
            (obj: {
              desc: string;
              code: string;
              detail_desc: string;
              checked?: boolean;
            }) => obj.desc
          )}
          list2={menuTwoSelectedList.map(
            (obj: {
              desc: string;
              code: string;
              detail_desc: string;
              checked?: boolean;
            }) => obj.desc
          )}
          ignoreNativeWrapperStyle={true}
        />
      </FilterWrapper>
      {noAssociatedPatient ? (
        // <CommonErrorMessage message="No patient in network" />
        <CommonErrorMessage message="Provider doesn't have any patient in network" />
      ) : isPatientListError ? (
        <CommonErrorMessage message=" There has been an error fetching the patient list" />
      ) : (
        // <div ref={scrollContainerRef}>
        <Table
          columns={columns}
          dataSource={patientData}
          rowKey="id"
          onRow={row => {
            return {
              onClick: () => {
                sessionStorage.setItem("patientId", String(row.id));
                patientContext.setSelectedPatientData(row);
                refetchPatientSurveyNotification();
                getNotificationList();
                if (userIsCaregiver) {
                  navigate(`${CaregiverRoutesRef.Symptoms}/${row.id}`);
                } else {
                  navigate(`${ProvidersRoutesRef.CareTeam}/${row.id}`);
                }
              }
            };
          }}
          pagination={false}
          loading={isLoadingPatientList || isPatientListRefetchLoading}
          data-testid="all-patients-table"
        />
        // </div>
      )}
    </AppCard>
    // </div>
  );
};

const Divider = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  margin: 16px 0;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1%;
`;

const FilterWrapper = styled.div`
  max-height: 120px;
  overflow: scroll;
  border: 1px solid ${Colors.Lavender};
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 10px;
  padding-bottom: 6px;
`;

const DateRowStyle = styled.div`
  font-size: 12px;
  color: ${Colors.Black};
`;
