import React, { useContext, useMemo, useState } from "react";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { UserMenu } from "components/userMenu";
import styled from "styled-components";
import { AppButton } from "components/button";
import { MailOutlined } from "@ant-design/icons";
import { ButtonType } from "components/button/appButton";
import { Colors } from "helpers/colors";
import { ReportSymptomsIcon } from "./ReportSymptomsIcon";
import { ReportSymptomsIconActive } from "./ReportSymptomsIconActive";
import { PatientsRoutesRef } from "constants/routes";
import { useNavigate } from "react-router";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { CircleIndicator } from "components/circleIndicator";
import { NotificationsDropdown } from "components/notificationsDropdown";
import OpenBookIcon from "../../svgIcons/openBook.svg";

export const TopbarButtons: React.FC = () => {
  const [reportIconActive, setReportIconActive] = useState(false);

  const navigate = useNavigate();

  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);

  const userId = sessionStorage.getItem("userId") || "";

  const unreadMessages = useMemo(() => {
    const unseenNotifications =
      notificationsContext.filterMessageNotificationsByStatus(
        notificationsContext.notifications?.messages || [],
        "unread"
      );
    return unseenNotifications.filter(
      item => String(userId) === String(item.notified_internal_id)
    );
  }, [notificationsContext.notifications?.messages]);

  return (
    <ButtonsContainer size={30} direction="horizontal">
      <RowStyled gutter={[10, 0]}>
        <AppButton
          type={ButtonType.Secondary}
          style={{
            backgroundColor: "#EAF3FC",
            marginRight: "10px",
            border: "none",
            boxShadow: "none"
          }}
          buttonContent={
            <Row gutter={[10, 0]}>
              <Col>
                <img src={OpenBookIcon} alt="bookIcon" width={30} />
              </Col>
            </Row>
          }
          onClick={() => navigate(PatientsRoutesRef.PatientEducation)}
        />
        <AppButton
          type={ButtonType.Primary}
          onMouseEnter={() => setReportIconActive(true)}
          onMouseLeave={() => setReportIconActive(false)}
          buttonContent={
            <Row gutter={[10, 0]} style={{ marginTop: "4px" }}>
              <Col>
                {!reportIconActive ? (
                  <ReportSymptomsIcon />
                ) : (
                  <ReportSymptomsIconActive />
                )}
              </Col>
              <ButtonText>
                <Col>Report Symptoms</Col>
              </ButtonText>
            </Row>
          }
          style={{ marginRight: "10px" }}
          onClick={() => navigate(PatientsRoutesRef.ReportSymptoms)}
        />
        <NotificationsDropdown />
        <MailButtonContainer
          isActive={window.location.pathname.includes(
            PatientsRoutesRef.AllMessages
          )}
          onClick={() => {
            navigate(PatientsRoutesRef.AllMessages);
          }}
        >
          {unreadMessages.length > 0 && (
            <CircleIndicatorWrapper>
              <CircleIndicator outerColor={"#fbddb7"} innerColor={"#f18e13"} />
            </CircleIndicatorWrapper>
          )}
          <AppButton
            buttonContent={<MailOutlined />}
            type={ButtonType.Link}
            style={{
              color: Colors.Black,
              fontSize: "20px",
              height: "24px"
            }}
          />
        </MailButtonContainer>
        <UserMenu />
      </RowStyled>
    </ButtonsContainer>
  );
};

interface MailButtonContainerProps {
  readonly isActive?: boolean;
}

const MailButtonContainer = styled.div<MailButtonContainerProps>`
  border: ${props =>
    `2px solid ${props.isActive ? Colors.DodgerBlue : "transparent"}`};
  background-color: ${Colors.Lavender};
  border-radius: 8px;
  margin-right: 6px;
  padding-top: 5px;
  cursor: pointer;
`;

const RowStyled = styled(Row)`
  margin-top: -15px;
  height: 45px;
`;

const ButtonsContainer = styled(Space)`
  position: fixed;
  top: 22px;
  right: 28px;
  z-index: 10;
`;

const ButtonText = styled.span`
  margin-left: 5px;

  @media screen and (max-width: 1400px) {
    display: none;
  }
`;

const CircleIndicatorWrapper = styled.span`
  position: absolute;
  margin-left: 27px;
`;
