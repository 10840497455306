import React, { useEffect, useState } from "react";

import Space from "antd/lib/space";
import Checkbox from "antd/lib/checkbox";
import Menu from "antd/lib/menu";

import styled from "styled-components";
import { Colors } from "helpers/colors";
import { CheckboxMenuCommonInterface } from "sharedTypes";

interface CheckboxMenuProps {
  readonly menuItems: CheckboxMenuCommonInterface[];
  getCheckedItems(
    checkedItems: {
      desc: string;
      code: string;
      detail_desc: string;
      checked?: boolean;
    }[]
  ): void;
  readonly applied?: boolean;
  setApplied?(applied: boolean): void;
}

export const CheckboxMenuComponent: React.FC<CheckboxMenuProps> = ({
  menuItems,
  getCheckedItems,
  setApplied
}) => {
  const [checkedItems, setCheckedItems] = useState<
    CheckboxMenuCommonInterface[]
  >([...menuItems]);

  useEffect(() => {
    getCheckedItems(checkedItems);
  }, [checkedItems]);

  const onSelectMenuItem = (item: string) => {
    if (setApplied) {
      setApplied(false);
    }
    const checkedTemp = [...checkedItems];

    const objIndex = checkedItems.findIndex(obj => obj.desc === item);

    checkedTemp[objIndex].checked = !checkedTemp[objIndex].checked;

    setCheckedItems(checkedTemp);
  };

  return (
    <MenuStyled>
      {checkedItems.map((item, index) => {
        return (
          <Menu.Item
            key={index}
            onClick={() => {
              onSelectMenuItem(item.desc);
            }}
          >
            <Space size={10}>
              <CheckboxStyled checked={item.checked} />
              {item.checked ? (
                <span style={{ fontWeight: "600" }}>{item.desc}</span>
              ) : (
                item.desc
              )}
            </Space>
          </Menu.Item>
        );
      })}
    </MenuStyled>
  );
};

const CheckboxStyled = styled(Checkbox)`
  pointer-events: none;
`;

const MenuStyled = styled(Menu)`
  width: auto;
  min-height: 312px;
  padding: 15px 6px 12px 6px;
  color: ${Colors.Grey};
  border-right: none;
  background-color: ${Colors.GhostWhite};

  .ant-menu-item {
    margin-top: -5px !important;
    :hover {
      background-color: ${Colors.BabyBlue};
      border-radius: 10px;
      transition: 0s;
      color: ${Colors.Black};
      overflow: visible;
      height: auto;
    }
  }

  .ant-menu-item-selected {
    background-color: ${Colors.BabyBlue} !important;
    border-radius: 10px;
    transition: 0s;
    color: ${Colors.Black};
  }
`;
