import React, { useContext, useRef, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Checkbox from "antd/lib/checkbox";
import message from "antd/lib/message";
import Radio from "antd/lib/radio";
import Form, { RuleObject } from "antd/lib/form";
import { useMutation } from "react-query";
import styled from "styled-components";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import { NephrologyLogo } from "pages/symptoms/surveyForms/nephrologyLogo";
import { SurveyInput } from "pages/symptoms/surveyForms/surveyInput";
import { Platform, UserRoles } from "constants/roles";
import { FormSubmitButton } from "pages/symptoms/surveyForms/FormSubmitButton";
import { FormClearButton } from "pages/symptoms/surveyForms/FormClearButton";

interface UlcersFormProps {
  setShowSurveyForm(show: boolean): void;
  selectedItems: string[];
  goToNextFormOrReturn(): void;
}

export const UlcersForm: React.FC<UlcersFormProps> = ({
  setShowSurveyForm,
  selectedItems,
  goToNextFormOrReturn
}) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  const [currentPage, setCurrentPage] = useState(1);
  const [otherTempInput, setOtherTempInput] = useState("");
  const otherInputRef = useRef("");
  const [showSubmit, setShowSubmit] = useState(false);

  const [form] = Form.useForm();

  const userContext = useContext<UserContextState>(UserContext);
  const patientContext = useContext<PatientContextState>(PatientContext);

  const validateWhereIsTheUlcer = (
    rules: RuleObject,
    value: string,
    callback: (error?: string) => void
  ) => {
    if (
      otherInputRef.current === "" &&
      localStorage.getItem("whereIsTheUlcer")?.includes("Other")
    ) {
      callback("Please enter value for 'Other' field.");
    } else {
      callback();
    }
  };

  const userRole = sessionStorage.getItem("userRole");

  const { isLoading: isLoadingUlcersMutation, mutate: submitUlcersForm } =
    useMutation<string>(
      "add-bill",
      async () => {
        return await createApiClient().addUlcersFormData({
          patient_internal_id:
            userRole === UserRoles.PATIENT && userContext.userData
              ? userContext.userData?.internal_id
              : patientContext?.patientData?.id || "",
          ulcers: localStorage.getItem("hasUlcers") || "",
          location: localStorage.getItem("whereIsTheUlcer") || "",
          size: localStorage.getItem("sizeOfLargestUlcer") || "",
          appearance: localStorage.getItem("appearanceOfUlcer") || "",
          submitted_by: `${userContext.userData?.firstName} ${userContext.userData?.lastName}`
        });
      },
      {
        onSuccess: () => {
          message.success("Form Submitted Successfully");
          goToNextFormOrReturn();
        },
        onError: () => {
          message.error({
            content: (
              <span>
                There was a problem submitting this form, resubmit or{" "}
                <a onClick={() => goToNextFormOrReturn()}>
                  click here to continue to the next survey
                </a>
              </span>
            ),
            style: {
              bottom: "10px",
              position: "fixed",
              left: "30%"
            },
            duration: 5
          });
        }
      }
    );

  const onFinish = () => {
    //Values to be passed on to API from session storage
    if (localStorage.getItem("hasUlcers") === "No") {
      //Clear form and session values if no longer pertinent.
      form.resetFields([
        "Level of pain",
        "Where is the ulcer",
        "Size of largest ulcer",
        "Appearance of ulcer"
      ]);
      localStorage.setItem("levelOfPain", "");
      localStorage.setItem("whereIsTheUlcer", "");
      localStorage.setItem("sizeOfLargestUlcer", "");
      localStorage.setItem("appearanceOfUlcer", "");
      submitUlcersForm();
    } else if (currentPage === 1) {
      setCurrentPage(2);
    } else {
      if (localStorage.getItem("whereIsTheUlcer")?.includes("Other")) {
        //To handle 'other' input from Where is the ulcer section
        const finalwhereIsTheUlcer =
          localStorage.getItem("whereIsTheUlcer") + "," + otherInputRef.current;
        localStorage.setItem("whereIsTheUlcer", finalwhereIsTheUlcer);
      }
      submitUlcersForm();
    }
  };

  const onClear = () => {
    if (currentPage === 1) {
      form.resetFields(["Any ulcers on skin"]);
    } else {
      form.resetFields([
        "Level of pain",
        "Where is the ulcer",
        "Size of largest ulcer",
        "Appearance of ulcer"
      ]);
      otherInputRef.current === "";
      setOtherTempInput("");
    }
  };

  return (
    <div>
      <div style={{ display: platform === Platform.MOBILE ? "none" : "block" }}>
        <AppButton
          type={ButtonType.Primary}
          onClick={() => setShowSurveyForm(false)}
          buttonContent={
            <>
              <ArrowLeftOutlined /> Back
            </>
          }
        />
      </div>
      <div style={{ display: "grid", placeItems: "center" }}>
        <Form
          name="ulcersForm"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          style={{ padding: "0px" }}
        >
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
              style={{ borderTop: "8px solid #4284f3" }}
            >
              <PageTitle>Survey - {selectedItems[0]}</PageTitle>
            </AppCard>
            {currentPage === 1 && (
              <>
                <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontSize: "16px",
                      fontWeight: "600"
                    }}
                  >
                    Please do not rely upon this survey for immediate medical
                    attention. This survey may not be reviewed by your care team
                    immediately. Please call your doctor for any medical
                    problems. IF YOU THINK THIS IS A MEDICAL EMERGENCY, OR YOUR
                    SYMPTOMS ARE WORRISOME PLEASE CALL 911 OR GO TO THE NEAREST
                    EMERGENCY ROOM.
                  </Space>
                </AppCard>

                <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontSize: "16px",
                      fontWeight: "600"
                    }}
                  >
                    <NephrologyLogo />
                  </Space>
                </AppCard>
                <Space direction="vertical" size={20}>
                  <AppCard
                    cardHeight="fit-content"
                    cardWidth={
                      platform === Platform.MOBILE
                        ? "calc(100vw - 60px)"
                        : "640px"
                    }
                  >
                    <Space
                      direction="vertical"
                      size={20}
                      style={{ width: "100%", padding: "10px" }}
                    >
                      <CardTitle>
                        Have you noticed any ulcers on your skin?
                        <AsteriskStyle> *</AsteriskStyle>
                      </CardTitle>
                      <FormItemStyle
                        name="Any ulcers on skin"
                        label=""
                        rules={[
                          {
                            required: true
                          }
                        ]}
                        initialValue={""}
                      >
                        <Radio.Group
                          value={localStorage.getItem("hasUlcers")}
                          onChange={e => {
                            if (e.target.value === "No") {
                              setShowSubmit(true);
                            } else {
                              setShowSubmit(false);
                            }
                            localStorage.setItem("hasUlcers", e.target.value);
                          }}
                        >
                          <Space direction="vertical">
                            <Radio value={"Yes"}>Yes</Radio>
                            <Radio value={"No"}>No</Radio>
                          </Space>
                        </Radio.Group>
                      </FormItemStyle>
                    </Space>
                  </AppCard>
                </Space>
              </>
            )}
            {currentPage === 2 && (
              <Space direction="vertical" size={20}>
                <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{ width: "100%", padding: "10px" }}
                  >
                    <CardTitle>
                      Where is the ulcer?
                      <AsteriskStyle> *</AsteriskStyle>
                    </CardTitle>
                    <FormItemStyle
                      name="Where is the ulcer"
                      label=""
                      rules={[
                        {
                          required: true
                        },
                        {
                          validator: validateWhereIsTheUlcer,
                          validateTrigger: "submit"
                        }
                      ]}
                      initialValue={""}
                    >
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        onChange={e =>
                          localStorage.setItem("whereIsTheUlcer", e.toString())
                        }
                        value={sessionStorage
                          .getItem("whereIsTheUlcer")
                          ?.split(/,/)}
                      >
                        <Row gutter={[10, 10]}>
                          <Col span={24}>
                            <Checkbox value="Legs/Feet">Legs/Feet</Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value="Buttocks / Back">
                              Buttocks / Back
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value="Abdomen">Abdomen</Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value="Arms">Arms</Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox value="Other">Other</Checkbox>
                            <SurveyInput
                              value={otherTempInput ?? otherInputRef}
                              placeholder={""}
                              onChange={e => {
                                setOtherTempInput(e.target.value);
                                otherInputRef.current = e.target.value;
                              }}
                              style={{ width: "70%", marginLeft: "30px" }}
                            />
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    </FormItemStyle>
                  </Space>
                </AppCard>
                <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{ width: "100%", padding: "10px" }}
                  >
                    <CardTitle>
                      What is size of the largest ulcer?
                      <AsteriskStyle> *</AsteriskStyle>
                    </CardTitle>
                    <FormItemStyle
                      name="Size of largest ulcer"
                      label=""
                      rules={[
                        {
                          required: true
                        }
                      ]}
                      initialValue={""}
                    >
                      <Radio.Group
                        value={localStorage.getItem("sizeOfLargestUlcer")}
                        onChange={e =>
                          localStorage.setItem(
                            "sizeOfLargestUlcer",
                            e.target.value
                          )
                        }
                      >
                        <Space direction="vertical">
                          <Radio value={"Small (less than 2 cm)"}>
                            Small (less than 2 cm)
                          </Radio>
                          <Radio value={"Medium (Between 2 cm and 5 cm)"}>
                            Medium (Between 2 cm and 5 cm)
                          </Radio>
                          <Radio value={"Big (more than 5 cm)"}>
                            Big (more than 5 cm)
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </FormItemStyle>
                  </Space>
                </AppCard>
                <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{ width: "100%", padding: "10px" }}
                  >
                    <CardTitle>
                      What is the appearance of the ulcer?
                      <AsteriskStyle> *</AsteriskStyle>
                    </CardTitle>
                    <FormItemStyle
                      name="Appearance of ulcer"
                      label=""
                      rules={[
                        {
                          required: true
                        }
                      ]}
                      initialValue={""}
                    >
                      <Radio.Group
                        value={localStorage.getItem("appearanceOfUlcer")}
                        onChange={e =>
                          localStorage.setItem(
                            "appearanceOfUlcer",
                            e.target.value
                          )
                        }
                      >
                        <Space direction="vertical">
                          <Radio value={"Dry"}>Dry</Radio>
                          <Radio value={"Moist"}>Moist</Radio>
                          <Radio value={"Draining"}>Draining</Radio>
                          <Radio value={"With an offensive odor"}>
                            With an offensive odor
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </FormItemStyle>
                  </Space>
                </AppCard>
              </Space>
            )}
            <Row>
              <Col span={5}>
                <Form.Item>
                  <FormClearButton onClear={onClear} />
                </Form.Item>
              </Col>
              <Col span={19} style={{ textAlign: "right" }}>
                <Form.Item>
                  <Space>
                    {currentPage !== 1 && (
                      <AppButton
                        type={ButtonType.Primary}
                        buttonContent={"Back"}
                        onClick={() => setCurrentPage(1)}
                        style={{ width: "90px" }}
                      />
                    )}
                    <FormSubmitButton
                      type={ButtonType.Primary}
                      buttonContent={
                        currentPage !== 1 || showSubmit ? "Submit" : "Next"
                      }
                      htmlType="submit"
                      loading={isLoadingUlcersMutation}
                    />
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </Form>
      </div>
    </div>
  );
};

const PageTitle = styled.div`
  font-size: 32px;
  font-weight: 600;
`;

const FormItemStyle = styled(Form.Item)`
  margin-bottom: 0px;
`;

const AsteriskStyle = styled.span`
  font-weight: 800;
  color: red;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-family: sans-serif;
`;
