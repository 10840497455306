import React, { useContext, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import message from "antd/lib/message";
import Radio from "antd/lib/radio";
import Form from "antd/lib/form";
import { useMutation } from "react-query";
import styled from "styled-components";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import { NephrologyLogo } from "pages/symptoms/surveyForms/nephrologyLogo";
import { Platform, UserRoles } from "constants/roles";
import { FormSubmitButton } from "pages/symptoms/surveyForms/FormSubmitButton";
import { FormClearButton } from "pages/symptoms/surveyForms/FormClearButton";

interface AppetiteImpairmentFormProps {
  setShowSurveyForm(show: boolean): void;
  selectedItems: string[];
  goToNextFormOrReturn(): void;
}

export const AppetiteImpairmentForm: React.FC<AppetiteImpairmentFormProps> = ({
  setShowSurveyForm,
  selectedItems,
  goToNextFormOrReturn
}) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  const [currentPage, setCurrentPage] = useState(1);
  const [showSubmit, setShowSubmit] = useState(false);

  const [form] = Form.useForm();

  const userContext = useContext<UserContextState>(UserContext);
  const patientContext = useContext<PatientContextState>(PatientContext);

  const userRole = sessionStorage.getItem("userRole");

  const {
    isLoading: isLoadingAppetiteImpairmentMutation,
    mutate: submitAppetiteImpairmentForm
  } = useMutation<string>(
    "add-bill",
    async () => {
      return await createApiClient().addAppetiteImpairmentFormData({
        patient_internal_id:
          userRole === UserRoles.PATIENT && userContext.userData
            ? userContext.userData?.internal_id
            : patientContext?.patientData?.id || "",
        appetite: localStorage.getItem("hasReducedAppetite") || "",
        level: localStorage.getItem("levelOfAppetiteImpairment") || "",
        submitted_by: `${userContext.userData?.firstName} ${userContext.userData?.lastName}`
      });
    },
    {
      onSuccess: () => {
        message.success("Form Submitted Successfully");
        goToNextFormOrReturn();
      },
      onError: () => {
        message.error({
          content: (
            <span>
              There was a problem submitting this form, resubmit or{" "}
              <a onClick={() => goToNextFormOrReturn()}>
                click here to continue to the next survey
              </a>
            </span>
          ),
          style: {
            bottom: "10px",
            position: "fixed",
            left: "30%"
          },
          duration: 5
        });
      }
    }
  );

  const onFinish = () => {
    //Values to be passed on to API from session storage
    if (localStorage.getItem("hasReducedAppetite") === "No") {
      form.resetFields(["Level of appetite impairment"]);
      localStorage.setItem("levelOfAppetiteImpairment", "");
      submitAppetiteImpairmentForm();
    } else if (currentPage === 1) {
      setCurrentPage(2);
    } else {
      submitAppetiteImpairmentForm();
    }
  };

  const onClear = () => {
    if (currentPage === 1) {
      form.resetFields(["Appetite Impairment"]);
    } else {
      form.resetFields(["Level of appetite impairment"]);
    }
  };

  return (
    <div>
      <div style={{ display: platform === Platform.MOBILE ? "none" : "block" }}>
        <AppButton
          type={ButtonType.Primary}
          onClick={() => setShowSurveyForm(false)}
          buttonContent={
            <>
              <ArrowLeftOutlined onClick={() => setShowSurveyForm(false)} />{" "}
              Back
            </>
          }
        />
      </div>
      <div style={{ display: "grid", placeItems: "center" }}>
        <Form
          name="appetiteImpairment"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          style={{ padding: "0px" }}
        >
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
              style={{ borderTop: "8px solid #4284f3" }}
            >
              <PageTitle>Survey - {selectedItems[0]}</PageTitle>
            </AppCard>
            {currentPage === 1 && (
              <>
                <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontSize: "16px",
                      fontWeight: "600"
                    }}
                  >
                    Please do not rely upon this survey for immediate medical
                    attention. This survey may not be reviewed by your care team
                    immediately. Please call your doctor for any medical
                    problems. IF YOU THINK THIS IS A MEDICAL EMERGENCY, OR YOUR
                    SYMPTOMS ARE WORRISOME PLEASE CALL 911 OR GO TO THE NEAREST
                    EMERGENCY ROOM.
                  </Space>
                </AppCard>

                <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontSize: "16px",
                      fontWeight: "600"
                    }}
                  >
                    <NephrologyLogo />
                  </Space>
                </AppCard>
                <Space direction="vertical" size={20}>
                  <AppCard
                    cardHeight="fit-content"
                    cardWidth={
                      platform === Platform.MOBILE
                        ? "calc(100vw - 60px)"
                        : "640px"
                    }
                  >
                    <Space
                      direction="vertical"
                      size={20}
                      style={{ width: "100%", padding: "10px" }}
                    >
                      <CardTitle>
                        Have you had reduced appetite in the past one month?
                        <AsteriskStyle> *</AsteriskStyle>
                      </CardTitle>
                      <FormItemStyle
                        name="Appetite Impairment"
                        label=""
                        rules={[
                          {
                            required: true
                          }
                        ]}
                        initialValue={""}
                      >
                        <Radio.Group
                          value={localStorage.getItem("hasReducedAppetite")}
                          onChange={e => {
                            if (e.target.value === "No") {
                              setShowSubmit(true);
                            } else {
                              setShowSubmit(false);
                            }
                            localStorage.setItem(
                              "hasReducedAppetite",
                              e.target.value
                            );
                          }}
                        >
                          <Space direction="vertical">
                            <Radio value={"Yes"}>Yes</Radio>
                            <Radio value={"No"}>No</Radio>
                          </Space>
                        </Radio.Group>
                      </FormItemStyle>
                    </Space>
                  </AppCard>
                </Space>
              </>
            )}
            {currentPage === 2 && (
              <Space direction="vertical" size={20}>
                <AppCard
                  cardHeight="fit-content"
                  cardWidth={
                    platform === Platform.MOBILE
                      ? "calc(100vw - 60px)"
                      : "640px"
                  }
                >
                  <Space
                    direction="vertical"
                    size={20}
                    style={{ width: "100%", padding: "10px" }}
                  >
                    <CardTitle>
                      Do you have
                      <AsteriskStyle> *</AsteriskStyle>
                    </CardTitle>
                    <FormItemStyle
                      name="Level of appetite impairment"
                      label=""
                      rules={[
                        {
                          required: true
                        }
                      ]}
                      initialValue={""}
                    >
                      <Radio.Group
                        value={localStorage.getItem(
                          "levelOfAppetiteImpairment"
                        )}
                        onChange={e =>
                          localStorage.setItem(
                            "levelOfAppetiteImpairment",
                            e.target.value
                          )
                        }
                      >
                        <Space direction="vertical">
                          <Radio
                            value={
                              "Mildly reduced appetite (rarely missing meals, eating about ¾ of usual)"
                            }
                          >
                            Mildly reduced appetite (rarely missing meals,
                            eating about ¾ of usual)
                          </Radio>
                          <Radio
                            value={
                              "Moderately reduced appetite (frequently missing meals/missing at least one meal a day, eating about ½ of usual)"
                            }
                          >
                            Moderately reduced appetite (frequently missing
                            meals/missing at least one meal a day, eating about
                            ½ of usual)
                          </Radio>
                          <Radio
                            value={
                              "Severely reduced appetite (mostly missing meals/no desire for food, eating about ¼ of usual)"
                            }
                          >
                            Severely reduced appetite (mostly missing meals/no
                            desire for food, eating about ¼ of usual)
                          </Radio>
                          <Radio value={"No appetite or intake of food"}>
                            No appetite or intake of food
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </FormItemStyle>
                  </Space>
                </AppCard>
              </Space>
            )}
            <Row>
              <Col span={5}>
                <Form.Item>
                  <FormClearButton onClear={onClear} />
                </Form.Item>
              </Col>
              <Col span={19} style={{ textAlign: "right" }}>
                <Form.Item>
                  <Space>
                    {currentPage !== 1 && (
                      <AppButton
                        type={ButtonType.Primary}
                        buttonContent={"Back"}
                        onClick={() => setCurrentPage(1)}
                        style={{ width: "90px" }}
                      />
                    )}
                    <FormSubmitButton
                      type={ButtonType.Primary}
                      buttonContent={
                        currentPage !== 1 || showSubmit ? "Submit" : "Next"
                      }
                      htmlType="submit"
                      loading={isLoadingAppetiteImpairmentMutation}
                    />
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </Form>
      </div>
    </div>
  );
};

const PageTitle = styled.div`
  font-size: 32px;
  font-weight: 600;
`;

const FormItemStyle = styled(Form.Item)`
  margin-bottom: 0px;
`;

const AsteriskStyle = styled.span`
  font-weight: 800;
  color: red;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-family: sans-serif;
`;
