import { EyeOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Colors } from "helpers/colors";

interface DropdownContentItemProps {
  readonly bold?: boolean;
}

interface DropdownContentContainerProps {
  readonly borderRadius?: string;
}

interface LoadingContainerProps {
  readonly height: string;
}

export const ProviderDetailsContainer = styled.div`
  width: 100%;
  padding: 0px 20px 0px 20px;
  display: flex;
  justify-content: space-between;
`;

export const ProviderAvatarContainer = styled.div`
  margin-left: 10px;
`;

interface ProviderNameStyledProps {
  epicLogin?: any;
  onCareTeam?: any;
}

export const ProviderName = styled.div<ProviderNameStyledProps>`
  font-size: 14px;
  color: ${Colors.gothicBold};
  font-weight: bold;
  margin-top: ${props =>
    props.onCareTeam && props.epicLogin === "true" ? "10px" : "0px"};
`;

export const ProviderBackLink = styled.a`
  text-decoration: none;
  color: ${Colors.gothicRegular};
  font-size: 12px;
  display: flex;
  align-items: center;
  :hover {
    color: ${Colors.gothicRegular};
  }
`;
export const ProviderDataItemContainer = styled.div`
  min-width: 80px;
  height: fit-content;
  border-left: 1px solid ${Colors.Gainsboro};
  padding-left: 10px;
  padding-right: 30px;
  margin-left: 10px;
`;
export const ProviderDataItemHeaderText = styled.div`
  color: ${Colors.gothicRegular};
  font-size: 12px;
  line-height: 15px;
`;
export const ProviderDataItemContentText = styled.div`
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;
export const ExpandArrowContainer = styled.span`
  padding-top: 3px;
  padding-left: 2px;
  cursor: pointer;
`;
export const ProviderDataContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const MoreDetailsContainer = styled.div`
  height: 44px;
  width: 44px;
  border: 1px solid ${Colors.gothicRegular};
  border-radius: 9.5px;
  display: grid;
  place-items: center;
  cursor: pointer;
  :hover {
    border: 1px solid ${Colors.DodgerBlue};
    color: ${Colors.DodgerBlue};
    box-shadow: 0 2px 8px 0 rgba(6, 157, 222, 0.2);
  }
`;

export const SharedPatientsTitleContainer = styled.div`
  display: flex;
  gap: 36px;
`;
export const SharedPatientsTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 15px;
`;
export const DropdownContainer = styled.div`
  width: 400px;
  background-color: ${Colors.GhostWhite};
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
`;
export const DropdownHeaderContainer = styled.div`
  padding: 15px;
  width: 400px;
  background-color: white;
  border-radius: 8px 8px 0px 0px;
  font-size: 14px;
  font-weight: bold;
`;
export const DropdownContentContainer = styled.div<DropdownContentContainerProps>`
  padding: 15px;
  width: 400px;
  background-color: ${Colors.GhostWhite};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : "")};
`;

export const DateRowStyle = styled.div`
  font-size: 12px;
  color: ${Colors.Black};
`;
export const StyledEyeOutlined = styled(EyeOutlined)`
  font-size: 20px;
`;
export const PhoneNumberDropdownContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: ${Colors.GhostWhite};
  padding: 15px;
`;
export const DropdownContentItemContainer = styled.div`
  padding: 5px 0px 5px 0px;
`;
export const DropdownContentItemTitle = styled.div`
  color: ${Colors.gothicRegular};
  font-size: 12px;
  line-height: 15px;
`;
export const DropdownContentItemContent = styled.div<DropdownContentItemProps>`
  font-size: 14px;
  padding-top: 5px;
  line-height: 15px;
  font-weight: ${props => (props.bold ? "bold" : "normal")};
`;

export const LoadingContainer = styled.div<LoadingContainerProps>`
  width: 100%;
  height: ${props => (props.height ? props.height : "100%")};
  display: grid;
  place-items: center;
`;
