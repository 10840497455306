export const resetCareTeamDropdownValues = () => {
  const careTeamMenuItems = [
    {
      desc: "Physicians",
      checked: true,
      value: "physician"
    },
    {
      desc: "Case Managers",
      checked: true,
      value: "case_manager"
    },
    {
      desc: "Dieticians",
      checked: true,
      value: "dietician"
    },
    {
      desc: "Nurses",
      checked: true,
      value: "nurse"
    },
    {
      desc: "Social Workers",
      checked: true,
      value: "social_worker"
    },
    {
      desc: "Care Givers",
      checked: true,
      value: "caregivers"
    },
    {
      desc: "Others",
      checked: true,
      value: "other"
    }
  ];

  sessionStorage.setItem(
    "careTeamCheckboxItems",
    JSON.stringify(careTeamMenuItems)
  );
};
