import { ArrowLeftOutlined } from "@ant-design/icons";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import message from "antd/lib/message";
import Radio from "antd/lib/radio";
import Form from "antd/lib/form";
import React, { useContext } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { UserContext, UserContextState } from "contextApis/userContext";
import { NephrologyLogo } from "pages/symptoms/surveyForms/nephrologyLogo";
import { Platform, UserRoles } from "constants/roles";
import { FormSubmitButton } from "pages/symptoms/surveyForms/FormSubmitButton";
import { FormClearButton } from "pages/symptoms/surveyForms/FormClearButton";

interface SurveyFormProps {
  setShowSurveyForm(show: boolean): void;
  selectedItems: string[];
  goToNextFormOrReturn(): void;
}

export const NauseaSurveyForm: React.FC<SurveyFormProps> = ({
  setShowSurveyForm,
  selectedItems,
  goToNextFormOrReturn
}) => {
  const params = new URLSearchParams(window.location.search);
  const platform = params.get("platform");
  const [form] = Form.useForm();
  const userContext = useContext<UserContextState>(UserContext);
  const patientContext = useContext<PatientContextState>(PatientContext);

  const userRole = sessionStorage.getItem("userRole");

  const { isLoading: isLoadingNauseaMutation, mutate: submitNauseaForm } =
    useMutation<string>(
      "add-bill",
      async () => {
        return await createApiClient().addNauseaFormData({
          patient_internal_id:
            userRole === UserRoles.PATIENT && userContext.userData
              ? userContext.userData?.internal_id
              : patientContext?.patientData?.id || "",
          nausea: "Yes",
          level: form.getFieldValue("Nausea Severity"),
          frequency: form.getFieldValue("Nausea Frequency"),
          submitted_by: `${userContext.userData?.firstName} ${userContext.userData?.lastName}`
        });
      },
      {
        onSuccess: () => {
          message.success("Form Submitted Successfully");
          goToNextFormOrReturn();
        },
        onError: () => {
          message.error({
            content: (
              <span>
                There was a problem submitting this form, resubmit or{" "}
                <a onClick={() => goToNextFormOrReturn()}>
                  click here to continue to the next survey
                </a>
              </span>
            ),
            style: {
              bottom: "10px",
              position: "fixed",
              left: "30%"
            },
            duration: 5
          });
        }
      }
    );

  const onFinish = () => {
    submitNauseaForm();
  };
  return (
    <div>
      <div style={{ display: platform === Platform.MOBILE ? "none" : "block" }}>
        <AppButton
          type={ButtonType.Primary}
          onClick={() => setShowSurveyForm(false)}
          buttonContent={
            <>
              <ArrowLeftOutlined onClick={() => setShowSurveyForm(false)} />{" "}
              Back
            </>
          }
        />
      </div>
      <div style={{ display: "grid", placeItems: "center" }}>
        <Form
          name="nausea"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          style={{ padding: "0px" }}
        >
          <Space direction="vertical" size={20}>
            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
              style={{ borderTop: "8px solid #4284f3" }}
            >
              <PageTitle>Survey - {selectedItems[0]}</PageTitle>
            </AppCard>

            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
            >
              <Space
                direction="vertical"
                size={20}
                style={{
                  width: "100%",
                  padding: "10px",
                  fontSize: "16px",
                  fontWeight: "600"
                }}
              >
                Please do not rely upon this survey for immediate medical
                attention. This survey may not be reviewed by your care team
                immediately. Please call your doctor for any medical problems.
                IF YOU THINK THIS IS A MEDICAL EMERGENCY, OR YOUR SYMPTOMS ARE
                WORRISOME PLEASE CALL 911 OR GO TO THE NEAREST EMERGENCY ROOM.
              </Space>
            </AppCard>

            <AppCard
              cardHeight="fit-content"
              cardWidth={
                platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
              }
            >
              <Space
                direction="vertical"
                size={20}
                style={{
                  width: "100%",
                  padding: "10px",
                  fontSize: "16px",
                  fontWeight: "600"
                }}
              >
                <NephrologyLogo />
              </Space>
            </AppCard>
            <Space direction="vertical" size={20}>
              <AppCard
                cardHeight="fit-content"
                cardWidth={
                  platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
                }
              >
                <Space
                  direction="vertical"
                  size={20}
                  style={{ width: "100%", padding: "10px" }}
                >
                  <CardTitle>
                    How often have you had nausea/vomiting in the last month?
                    <AsteriskStyle> *</AsteriskStyle>
                  </CardTitle>
                  <FormItemStyle
                    name="Nausea Frequency"
                    label=""
                    rules={[
                      {
                        required: true
                      }
                    ]}
                    initialValue={""}
                  >
                    <Radio.Group value={""}>
                      <Space direction="vertical">
                        <Radio value={"Rarely (1 - 3 times)"}>
                          Rarely (1 - 3 times)
                        </Radio>
                        <Radio value={"Occasionally (4 - 7 times)"}>
                          Occasionally (4 - 7 times)
                        </Radio>
                        <Radio value={"Frequently (8 - 15 times)"}>
                          Frequently (8 - 15 times)
                        </Radio>
                        <Radio value={"Nearly everyday"}>Nearly everyday</Radio>
                      </Space>
                    </Radio.Group>
                  </FormItemStyle>
                </Space>
              </AppCard>

              <AppCard
                cardHeight="fit-content"
                cardWidth={
                  platform === Platform.MOBILE ? "calc(100vw - 60px)" : "640px"
                }
              >
                <Space
                  direction="vertical"
                  size={20}
                  style={{ width: "100%", padding: "10px" }}
                >
                  <CardTitle>
                    How severe is your nausea/vomiting?
                    <AsteriskStyle> *</AsteriskStyle>
                  </CardTitle>
                  <FormItemStyle
                    name="Nausea Severity"
                    label=""
                    rules={[
                      {
                        required: true
                      }
                    ]}
                    initialValue={""}
                  >
                    <Radio.Group value={""}>
                      <Space direction="vertical">
                        <Radio value={"Mild nausea only"}>
                          Mild nausea only
                        </Radio>
                        <Radio value={"Moderate nausea, occasional vomiting"}>
                          Moderate nausea, occasional vomiting
                        </Radio>
                        <Radio value={"Severe nausea / retching / vomiting"}>
                          Severe nausea / retching / vomiting
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </FormItemStyle>
                </Space>
              </AppCard>
              <Row>
                <Col span={5}>
                  <Form.Item>
                    <FormClearButton onClear={() => form.resetFields()} />
                  </Form.Item>
                </Col>
                <Col span={19} style={{ textAlign: "right" }}>
                  <Form.Item>
                    <FormSubmitButton
                      type={ButtonType.Primary}
                      buttonContent="Submit"
                      htmlType="submit"
                      loading={isLoadingNauseaMutation}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Space>
          </Space>
        </Form>
      </div>
    </div>
  );
};

const PageTitle = styled.div`
  font-size: 32px;
  font-weight: 600;
`;

const FormItemStyle = styled(Form.Item)`
  margin-bottom: 0px;
`;

const AsteriskStyle = styled.span`
  font-weight: 800;
  color: red;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-family: sans-serif;
`;
