import React, { useContext, useEffect, useMemo, useState } from "react";
import Space from "antd/lib/space";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import message from "antd/lib/message";
import Skeleton from "antd/lib/skeleton";
import styled from "styled-components";
import { TopbarExtension } from "components/topbarExtension";
import { AppointmentIcon } from "./appointmentIcon";
import { CallButton } from "./callButton";
import { VideoIcon } from "./videoIcon";
import { AppAvatar } from "components/avatar";
import { Colors } from "helpers/colors";
import { MessageIcon } from "pages/patients/messageIcon";
import { useNavigate } from "react-router-dom";
import { CaregiverRoutesRef, ProvidersRoutesRef } from "constants/routes";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import moment from "moment";
import { createApiClient } from "apiClient";
import { useQuery, useMutation } from "react-query";
import {
  CallRecordBody,
  CallRecordFormData,
  PatientProfileData
} from "sharedTypes";
import {
  MeetingModalContext,
  MeetingContextState,
  sessionStatusValues
} from "contextApis/meetingModalContext";
import {
  ProviderAvatarContainer,
  ProviderBackLink,
  ProviderName
} from "pages/providerDetails/style";
import BackArrow from "svgIcons/backArrow";
import { AppModal } from "components/modal";
import { Radio, Select, Spin } from "antd";
import { UserRoles } from "constants/roles";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import { CircleIndicator } from "components/circleIndicator";
import { CareGemLogo } from "../../components/caregiverTopbar/careGemLogo";
import { EditCallRecord } from "pages/callRecords/EditCallRecord";
import { AddCallRecord } from "pages/callRecords/AddCallRecord";
import { UpdateCallRecordAfterCallEnded } from "pages/callRecords/UpdateCallRecordAfterCallEnded";
import { CallRecord } from "pages/callRecords/CallRecordList";
import { customRound } from "helpers/utils";
import { SelectedCallRecordDraft } from "pages/callRecords/callRecords";
import { UserContext, UserContextState } from "contextApis/userContext";
import { sortBy } from "lodash";
import { Phone } from "amazon-chime-sdk-component-library-react";
import { SessionStateControllerAction } from "amazon-chime-sdk-js";

interface PutCallRecordProps {
  patientId: string;
  callId: string | number;
  body: CallRecordBody;
}

export const CommonPatientExtensionItems: React.FC = () => {
  const navigate = useNavigate();
  const patientContext = useContext<PatientContextState>(PatientContext);
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);
  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);
  const [showPhoneNumberSelectModal, setShowPhoneNumberSelectModal] =
    useState<boolean>(() => {
      const storedValue = sessionStorage.getItem("showPhoneNumberPopup");
      return storedValue === "true";
    });

  const [showAddEditCallRecords, setShowAddEditCallRecords] = useState("false");
  // const [showUpdateCallDetailsPopup, setShowUpdateCallDetailsPopup] =
  //   useState(false);
  // const [showAddCallConfirmationModal, setShowAddCallConfirmationModal] =
  //   useState(false);
  const currentPath = location.pathname; // Extract the pathname
  const parts = currentPath.split("/");
  const patientId = parts[parts.length - 1];
  // const patientId = sessionStorage.getItem("patientId");
  const epicLogin: any = sessionStorage.getItem("epicLogin");
  const userContext = useContext<UserContextState>(UserContext);
  const [allCallers, setAllCallers] = useState<any>([]);

  const { mutate: putCallRecord, isLoading: isLoadingPutCallRecords } =
    useMutation(
      "edit-call-record",
      async (props: PutCallRecordProps) => {
        /* eslint-disable */
        createApiClient().putCallRecord(
          props.patientId,
          props.callId,
          props.body
        );
        /* eslint-enable */
      },
      {
        onSuccess: () => {
          message.success("Call record details saved");
          setTimeout(() => {
            getCallRecords();
            meetingContext.setRefreshCallRecordsStatus(true);
          }, 500);
        },
        onError: () => {
          message.error("There was a problem adding call record");
        }
      }
    );

  const { isLoading: isLoadingDeleteCallRecord, mutate: deleteCallRecord } =
    useMutation<string>(
      "delete-call-record",
      async () => {
        return await createApiClient().deleteCallRecord(
          patientId ? patientId : "",
          selectedDraft.id
        );
      },
      {
        onSuccess: () => {
          message.success("Not Saved!");
          setTimeout(() => {
            getCallRecords();
            meetingContext.setRefreshCallRecordsStatus(true);
          }, 500);
        },
        onError: () => {
          // message.error("There was a problem deleting this record");
        }
      }
    );

  const [changedDraft, setChangedDraft] = useState(false);

  const [selectedDraft, setSelectedDraft] = useState<any>();

  const { data: callRecordNotesData, isLoading: isLoadingCallRecordNotes } =
    useQuery("call-record-notes", async () => {
      return await createApiClient().getCallRecordNotes();
    });

  const userIsCaregiver =
    sessionStorage.getItem("userRole") === UserRoles.CAREGIVER;

  const onBackLinkClick = () =>
    navigate(
      `/${
        userIsCaregiver
          ? CaregiverRoutesRef.Patients
          : ProvidersRoutesRef.Patients
      }`
    );

  const userId = sessionStorage.getItem("userId") || "";

  const callFinalizingPopUp = () => {
    meetingContext.setShowUpdateCallDetailsPopup(true);
    meetingContext.setSessionStatus(sessionStatusValues.NOTSTARTED);
  };

  const {
    isLoading: isGetCallRecordsLoading,
    data: getCallRecordsData,
    error,
    refetch: getCallRecords,
    isFetching: isFetchingCallRecords
  } = useQuery<any>("call-records", async () => {
    const response = await createApiClient().getCallRecords(
      patientContext.patientData?.internal_id
        ? patientContext.patientData.internal_id
        : "",
      true
    );

    const latestCallRecord = response?.map(x => {
      return {
        id: x.id,
        date: x.date_p,
        itemsDiscussed: x.notes,
        duration: x.call_duration,
        caller: "",
        other: x.desc,
        callType: x.call_type
      };
    })[0];
    setSelectedDraft(latestCallRecord);

    if (meetingContext.sessionStatus === sessionStatusValues.ENDED) {
      latestCallRecord?.duration < 59
        ? !userContext.isProviderModal &&
          meetingContext.setShowAddCallConfirmationModal(true)
        : !userContext.isProviderModal && callFinalizingPopUp();
    }
    return response;
  });

  useEffect(() => {
    console.log(sessionStorage?.getItem("showPhoneNumberPopup"));
  }, []);

  useEffect(() => {
    if (
      meetingContext.fetchCallRecords &&
      meetingContext.sessionStatus === sessionStatusValues.ENDED
    ) {
      setTimeout(() => {
        getCallRecords();
      }, 500);
    }
  }, [
    meetingContext.fetchCallRecords,
    getCallRecordsData,
    meetingContext.sessionStatus
  ]);

  const convertDateToLocal = (value: string | Date, format?: string) => {
    return moment.utc(value, format).local();
  };

  const getSortedData = (data: any) => {
    return sortBy(data, o => Date.parse(o?.date_p ?? ""))?.reverse();
  };

  const checkCurrentDateRecord = (data: any) => {
    const currentDate = convertDateToLocal(new Date()).format("MMM D, YYYY");
    return (
      data?.date &&
      convertDateToLocal(data?.date).format("MMM D, YYYY") === currentDate
    );
  };

  const unreadMessageNotifications = useMemo(() => {
    const unseenNotifications =
      notificationsContext.filterMessageNotificationsByStatus(
        notificationsContext.notifications?.messages || [],
        "unread"
      );
    return unseenNotifications;
  }, [notificationsContext.notifications?.messages]);

  const unreadMessages = unreadMessageNotifications.filter(
    item =>
      String(userId) === String(item.notified_internal_id) &&
      String(patientContext.patientData?.internal_id) ===
        String(item.sender_internal_id)
  );

  const {
    data: patientProfileData,
    isLoading,
    isFetching,
    status
  } = useQuery<PatientProfileData>(
    [patientId],
    async () => {
      return await createApiClient().getPatientProfile(
        patientId ? patientId : "",
        true
      );
    },
    {
      onSuccess: data => {
        if (data) {
          const tempPatientData: any = {
            phoneNumbers: data.phoneNumbers,
            dob: data.dob,
            age: moment().diff(moment(data.dob, "MM-DD-YYYY"), "years"),
            last_name: data.last_name,
            first_name: data.first_name,
            id: data.id,
            picture: data.picture,
            name: data.name,
            internal_id: data.internal_id.toString(),
            username: data.username
          };
          patientContext.setSelectedPatientData(tempPatientData);
        }
      },
      onError: code => {
        if (status === "loading") {
          message.error("Patient detail not found, returning home.");
          setTimeout(() => {
            window.location.href =
              window.location.protocol +
              "//" +
              window.location.host +
              "/" +
              (userIsCaregiver
                ? CaregiverRoutesRef.Patients
                : ProvidersRoutesRef.Patients);
          }, 3000);
          return;
        }
        if (!patientProfileData) {
          message.error(
            "There was a problem retreiving patient data, returning home."
          );
          setTimeout(() => {
            window.location.href =
              window.location.protocol +
              "//" +
              window.location.host +
              "/" +
              (userIsCaregiver
                ? CaregiverRoutesRef.Patients
                : ProvidersRoutesRef.Patients);
          }, 3000);
        }
      }
    }
  );
  const onFormSubmit = (formData: CallRecordFormData) => {
    console.log("formdata", formData);

    const callRecordBody: CallRecordBody = {
      callDateTime: moment.utc(formData.date).format("MM-DD-YYYY HH:mm"),
      callLength: (Number(formData.duration) * 60).toString(),
      notes: [
        ...formData.discussionItems.map(item => ({
          desc: item.note_detail,
          id: item.id.toString(),
          isSelected: "true"
        })),
        { desc: formData.otherIssues, id: "999" }
      ],
      patient_id: patientContext.patientData?.id.toString() || "",
      provider_id: userContext.userData?.internal_id.toString() || "",
      typeOfCall: selectedDraft?.callType ? selectedDraft?.callType : "Manual",
      status: formData.status
    };
    if (formData.id) {
      console.log("formData.id", formData.id);
      meetingContext.setShowAddCallConfirmationModal(false);
      meetingContext.setShowUpdateCallDetailsPopup(false);
      putCallRecord({
        patientId: patientContext.patientData?.id.toString() || "",
        callId: formData.id || "",
        body: callRecordBody
      });
    } else {
      // postCallRecord(callRecordBody);
      console.log("line no. 320");
    }
  };

  const homeNumberSameAsOffice =
    patientContext.patientData?.phoneNumbers[0].number ===
    patientContext.patientData?.phoneNumbers[1].number;

  const onMessageIconClick = () => {
    navigate(
      (userIsCaregiver
        ? CaregiverRoutesRef.PatientMessages
        : ProvidersRoutesRef.PatientMessages) +
        "/" +
        patientId
    );
  };
  const { data: allCallersData } = useQuery(
    "fething-twilio-callers",
    async () => {
      return await createApiClient().getTwilioCallers();
    },
    {
      enabled: true,
      cacheTime: 0,
      staleTime: 0,
      onSuccess: (data: any) => {
        const response =
          data?.map((item: any) => {
            return {
              value: item?.caller_id,
              label: item?.alias + "   " + item?.caller_id
            };
          }) || [];
        setAllCallers(response);
      },
      onError: () => {
        message.error("There was a problem to get all callers data.");
      }
    }
  );

  return (
    <TopbarExtension>
      <RowStyled>
        <TitleCol span={epicLogin === "true" ? 12 : 18}>
          <Row>
            <Col>
              <AppAvatar
                imageSrc={patientProfileData?.picture || ""}
                size="large"
              />
            </Col>
            <Col>
              {isFetching || isLoading ? (
                <Skeleton
                  loading={isFetching || isLoading}
                  active={isFetching || isLoading}
                  avatar={false}
                  paragraph={false}
                  style={{ width: 50 }}
                />
              ) : (
                <ProviderAvatarContainer>
                  <ProviderName
                    epicLogin={epicLogin}
                    onCareTeam={"CareTeam"}
                  >{`${patientContext.patientData?.last_name || ""}, ${
                    patientContext.patientData?.first_name || ""
                  }`}</ProviderName>
                  {epicLogin !== "true" && (
                    <ProviderBackLink onClick={onBackLinkClick}>
                      <BackArrow /> Back to Patients
                    </ProviderBackLink>
                  )}
                </ProviderAvatarContainer>
              )}
            </Col>
            <DateAgeCol span={12}>
              <div
                style={{
                  width: "1px",
                  backgroundColor: Colors.Lavender,
                  height: "35px",
                  marginTop: "-6px",
                  display: "inline-block"
                }}
              />
              <ContentContainer>
                {isFetching || isLoading ? (
                  <Skeleton
                    loading={isFetching || isLoading}
                    active={isFetching || isLoading}
                    avatar={false}
                    paragraph={false}
                    style={{ width: 50 }}
                  />
                ) : (
                  <>
                    <MessageUpperRow>
                      {moment(
                        patientContext.patientData?.dob,
                        "MM-DD-YYYY"
                      ).format("MMM DD, YYYY")}
                    </MessageUpperRow>
                    <MessageLowerRow>{`${patientContext.patientData?.age} years`}</MessageLowerRow>
                  </>
                )}
              </ContentContainer>
            </DateAgeCol>
          </Row>
        </TitleCol>

        {epicLogin === "true" && (
          <CaregemIcon span={6}>
            <CareGemLogo />
          </CaregemIcon>
        )}

        <ButtonsCol span={6}>
          <Space size={12}>
            {/* Temp Icons used until design team provides usage items */}
            {!userIsCaregiver && (
              <span
                onClick={() => {
                  navigate(ProvidersRoutesRef.Appointment + "/" + patientId);
                }}
                style={{ cursor: "pointer" }}
              >
                <AppointmentIcon
                  isActive={window.location.pathname.includes(
                    ProvidersRoutesRef.Appointment
                  )}
                />
              </span>
            )}
            <span onClick={onMessageIconClick} style={{ cursor: "pointer" }}>
              {unreadMessages.length > 0 && (
                <CircleIndicatorWrapper>
                  <CircleIndicator
                    outerColor={"#fbddb7"}
                    innerColor={"#f18e13"}
                  />
                </CircleIndicatorWrapper>
              )}
              <MessageIcon
                isActive={window.location.pathname.includes(
                  userIsCaregiver
                    ? CaregiverRoutesRef.PatientMessages
                    : ProvidersRoutesRef.PatientMessages
                )}
              />
            </span>

            {!userIsCaregiver && (
              <span
                onClick={() => {
                  if (meetingContext.targetUser.name) {
                    return;
                  } else {
                    meetingContext.setTargetUser(
                      patientContext.patientData?.first_name +
                        " " +
                        patientContext.patientData?.last_name,
                      String(patientContext.patientData?.id)
                    );
                    meetingContext.setCurrentPage(1);
                    meetingContext.maximize(true);
                  }
                }}
                style={{
                  cursor: meetingContext.targetUser.name
                    ? "not-allowed"
                    : "pointer"
                }}
              >
                <VideoIcon
                  isActive={
                    meetingContext.targetUser.name &&
                    !meetingContext.pstnPhoneNumber
                      ? true
                      : false
                  }
                />
              </span>
            )}
            <span
              onClick={() => {
                if (meetingContext.targetUser.name) {
                  return;
                } else {
                  sessionStorage.setItem("showPhoneNumberPopup", "true");
                  sessionStorage.setItem("alert_call", "false");
                  sessionStorage.setItem("group_id", "");
                  // Convert the sessionStorage value to a boolean
                  const showPopup =
                    sessionStorage.getItem("showPhoneNumberPopup") === "true";

                  setShowPhoneNumberSelectModal(showPopup);
                  userContext.setIsProviderModal(false);
                }
              }}
              style={{
                cursor: meetingContext.targetUser.name
                  ? "not-allowed"
                  : "pointer"
              }}
            >
              {isLoading ? (
                <Spin />
              ) : (
                <CallButton
                  isActive={
                    meetingContext.targetUser.name &&
                    meetingContext.pstnPhoneNumber
                      ? true
                      : false
                  }
                />
              )}
            </span>
          </Space>

          {showPhoneNumberSelectModal && (
            <AppModal
              visible={showPhoneNumberSelectModal}
              title={
                homeNumberSameAsOffice
                  ? "Call Confirmation"
                  : "Select Phone Number"
              }
              cancelText="Cancel"
              okText={"Call"}
              onCancel={() => {
                // phoneNumberSelectModalContext?.togglePhoneNumberSelectModal();
                // setShowPhoneNumberSelectModal(false);S

                sessionStorage.setItem("showPhoneNumberPopup", "false");

                setShowPhoneNumberSelectModal(false);
                meetingContext.setPSTNPhoneNumber("");
              }}
              disablePrimaryButton={
                !meetingContext.pstnPhoneNumber && !homeNumberSameAsOffice
              }
              onOk={() => {
                meetingContext.setTargetUser(
                  patientContext.patientData?.first_name +
                    " " +
                    patientContext.patientData?.last_name,
                  String(patientContext.patientData?.id)
                );
                meetingContext.setCurrentPage(3);
                meetingContext.maximize(true);
                sessionStorage.setItem("showPhoneNumberPopup", "false");
                setShowPhoneNumberSelectModal(false);
                if (homeNumberSameAsOffice && patientContext.patientData) {
                  meetingContext.setPSTNPhoneNumber(
                    patientContext.patientData?.phoneNumbers[0].number.replaceAll(
                      "-",
                      ""
                    )
                  );
                }
              }}
            >
              <StyleSelect className="selectStyle">
                {patientContext.patientData?.phoneNumbers && (
                  <div>
                    <h3>From :</h3>
                    <Select
                      style={{ width: 200 }}
                      defaultValue={allCallers[0]?.value}
                      onChange={(value: string) =>
                        meetingContext.setFromPhoneNumber(value)
                      }
                      options={allCallers}
                    />
                  </div>
                )}

                {homeNumberSameAsOffice ? (
                  <CommonPhoneNumberView direction="vertical">
                    <div>Cell and Home</div>
                    <div>
                      <span>To :</span>
                      {patientContext.patientData?.phoneNumbers[0].number}
                    </div>
                  </CommonPhoneNumberView>
                ) : (
                  <div>
                    <h3>To :</h3>
                    <Radio.Group>
                      <Space direction="vertical">
                        {patientContext.patientData?.phoneNumbers.map(
                          (item, index) => {
                            return (
                              <Radio
                                value={index}
                                key={index}
                                onClick={() =>
                                  meetingContext.setPSTNPhoneNumber(
                                    item.number.replaceAll("-", "")
                                  )
                                }
                              >
                                {`${item.number} (${item.title})`}
                              </Radio>
                            );
                          }
                        )}
                      </Space>
                    </Radio.Group>
                  </div>
                )}
              </StyleSelect>
            </AppModal>
          )}

          <AppModal
            visible={meetingContext.showAddCallConfirmationModal}
            title=""
            cancelText="No"
            okText="Yes"
            closable={false}
            onCancel={() => {
              deleteCallRecord();
              meetingContext.setShowAddCallConfirmationModal(false);
              meetingContext.setSessionStatus(sessionStatusValues.NOTSTARTED);
            }}
            onOk={() => {
              callFinalizingPopUp();
            }}
            prompt={{
              icon: <></>,
              text: <div>Do you want to Add Call Details ?</div>
            }}
            footer
          />

          <AppModal
            // visible={meetingContext.fetchCallRecords}
            visible={meetingContext.showUpdateCallDetailsPopup}
            title="Add Call Details"
            footer={false}
            closable={true}
            onCancel={() => {
              deleteCallRecord();
              meetingContext.setShowUpdateCallDetailsPopup(false);
              meetingContext.setShowAddCallConfirmationModal(false);
            }}
          >
            <UpdateCallRecordAfterCallEnded
              onFormSubmit={onFormSubmit}
              selectedDraft={selectedDraft}
              setShowAddEditCallRecords={setShowAddEditCallRecords}
              isLoadingPutCallRecord={isLoadingPutCallRecords}
              changedDraft={changedDraft}
              setChangedDraft={setChangedDraft}
              setSelectedDraft={setSelectedDraft}
              disableForm={isFetchingCallRecords || isLoadingPutCallRecords}
              isLoadingCallRecordNotes={isLoadingCallRecordNotes}
              callRecordNotesData={callRecordNotesData || []}
              getCallRecords={getCallRecords}
            />
          </AppModal>
        </ButtonsCol>
      </RowStyled>
    </TopbarExtension>
  );
};

const CommonPhoneNumberView = styled(Space)`
  text-align: center;
  font-weight: bold;
  width: 100%;
`;

const ContentContainer = styled.div`
  margin-left: 12px;
  display: inline-block;
  font-size: 12px;
  color: ${Colors.gothicRegular};
  position: absolute;
  margin-top: -9px;
`;

const MessageUpperRow = styled(Row)``;

const MessageLowerRow = styled(Row)``;

const TitleCol = styled(Col)`
  padding: 12px;
`;

const DateAgeCol = styled(Col)`
  padding: 10px;
`;

const CaregemIcon = styled(Col)`
  padding-top: 15px;
`;

const ButtonsCol = styled(Col)`
  text-align: right;
  padding: 11px;
`;

const RowStyled = styled(Row)`
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
`;

const CircleIndicatorWrapper = styled.span`
  position: absolute;
  margin-left: 27px;
`;

const SelectBoxStyle = styled.div`
  margin-bottom: 10px;
`;

const StyleSelect = styled.div`
  display: flex;
  justify-content: space-around;
`;
