import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";

import { AppTabs } from "components/tabs";
import { BloodPressureTable } from "./bloodPressureTable";
import { WeightTable } from "./weightTable";
import { AppSelect } from "components/inputs";
import { createApiClient } from "apiClient";
import {
  WeightVitals,
  BloodPressureVitals,
  ModifyBloodPressureVitals,
  ModifyWeightVitals
} from "sharedTypes";
import { CommonErrorMessage } from "components/CommonErrorMessage";
import { UserRoles } from "constants/roles";
import { useParams } from "react-router";
import { weightMapper } from "./weightMapper";
import { RemoteVitalsChartsModal } from "./remoteVitalsChartsModal";
import { bloodPressureMapper } from "./bloodPressureMapper";
import {
  NotificationsContext,
  NotificationsContextState
} from "contextApis/notificationsContext";
import moment from "moment";
import "moment-timezone";

interface SeriesData {
  name: string;
  readonly data: [number | string, number][];
}

export enum RemoteVitalsTypes {
  BloodPressure = "Blood Pressure",
  Weight = "Weight",
  OtherRemoteData = "Other Remote Data"
}

export const RemoteVitalsTabs: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<RemoteVitalsTypes>(
    RemoteVitalsTypes.BloodPressure
  );
  const [typeFilter, setTypeFilter] = useState("All");
  const getSelectedTab = (key: RemoteVitalsTypes) => {
    setSelectedTab(key);
  };

  const [timeRange, setTimeRange] = useState<any>(12);
  const [weightData, setWeightData] =
    useState<(BloodPressureVitals | WeightVitals)[]>();
  const [weightChartsData, setWeightChartsData] =
    useState<[string, number, string][][]>();
  const [bloodPressureData, setBloodPressureData] =
    useState<(BloodPressureVitals | WeightVitals)[]>();
  const [bloodPressureChartsData, setBloodPressureChartsData] =
    useState<{ data: [string, number, string][][]; series: SeriesData[][] }>();
  const notificationsContext =
    useContext<NotificationsContextState>(NotificationsContext);
  const [displayRange, setDisplayRange] = useState<string>("12 Months");
  const [lableRange, setLableRange] = useState<string>("12 Months");

  const timeZone = moment.tz.guess();

  const userIsPatient =
    sessionStorage.getItem("userRole") === UserRoles.PATIENT;

  const userId = sessionStorage.getItem("userId") || "";

  const { id } = useParams();

  const [showVitalsCharts, setShowVitalsCharts] = useState("");

  const {
    isLoading: isLoadingRemoteVitalsData,
    error: isRemoteVitalsDataError,
    refetch: getRemoteVitalsData,
    isRefetching: refetchRemoteVitalsLoading
  } = useQuery(
    [
      "get-remote-vitals-data",
      notificationsContext.notifications?.remote_vitals
    ],
    async () => {
      return await createApiClient().getRemoteVitalsData(
        userIsPatient ? userId : id ? id : "",
        typeFilter,
        timeRange,
        timeZone,
        true
      );
    },
    {
      onSuccess: data => {
        function isWeightVitals(
          object: WeightVitals | BloodPressureVitals
        ): object is WeightVitals {
          return "weight_taken_on" in object;
        }

        let weightData: ModifyWeightVitals[] = data?.filter(item =>
          isWeightVitals(item)
        ) as ModifyWeightVitals[];

        weightData = weightData.map(item => {
          return item;
        });

        let bloodPressureData: ModifyBloodPressureVitals[] = data?.filter(
          item => !isWeightVitals(item)
        ) as ModifyBloodPressureVitals[];

        bloodPressureData = bloodPressureData.map(item => {
          return item;
        });

        const weightCharts = weightMapper({
          data: weightData as WeightVitals[]
        });

        const bloodPressureCharts = bloodPressureMapper({
          data: bloodPressureData as BloodPressureVitals[]
        });

        setWeightData(weightData);
        setBloodPressureData(bloodPressureData);
        setBloodPressureChartsData(bloodPressureCharts);
        // setBloodPressureAMPMChartsData(bloodPressureCharts);
        setWeightChartsData(weightCharts);
      }
    }
  );

  const patientId = sessionStorage.getItem("patientId");

  useEffect(() => {
    getRemoteVitalsData();
  }, [typeFilter, timeRange, patientId]);

  const tabsContent = [
    {
      key: RemoteVitalsTypes.BloodPressure,
      tabName: RemoteVitalsTypes.BloodPressure
    },
    {
      key: RemoteVitalsTypes.Weight,
      tabName: RemoteVitalsTypes.Weight
    }
    // {
    //   key: RemoteVitalsTypes.OtherRemoteData,
    //   tabName: "Other Remote Data"
    // }
  ];

  const timeRangeOptions: { label: string; value: any }[] = [
    {
      label: "1 Month",
      value: 1
    },
    {
      label: "3 Months",
      value: 3
    },
    {
      label: "6 Months",
      value: 6
    },
    {
      label: "12 Months",
      value: 12
    }
  ];

  const onTimeRangeChange = (range: any) => {
    setDisplayRange(range);
    const selectedOption = timeRangeOptions.find(
      option => option.value === range
    );

    if (selectedOption) {
      setLableRange(selectedOption.label);
      setTimeRange(range);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {showVitalsCharts && (
        <RemoteVitalsChartsModal
          isModalOpen={showVitalsCharts ? true : false}
          onModalClose={() => setShowVitalsCharts("")}
          selectedVital={showVitalsCharts}
          weightData={
            showVitalsCharts === RemoteVitalsTypes.Weight
              ? weightChartsData
              : undefined
          }
          bloodPressureChartsData={
            showVitalsCharts === RemoteVitalsTypes.BloodPressure
              ? bloodPressureChartsData
              : undefined
          }
          setShowVitalsCharts={setShowVitalsCharts}
          weightRawData={weightData as WeightVitals[]}
          bloodPressureRawData={bloodPressureData as BloodPressureVitals[]}
          showVitalsCharts={showVitalsCharts}
        />
      )}
      <div>
        <AppTabs
          defaultActiveKey={selectedTab}
          tabsContent={tabsContent}
          tabPosition="top"
          getSelectedTab={getSelectedTab}
        />
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 20
        }}
      >
        <AppSelect
          value={displayRange}
          label="Show"
          onChange={onTimeRangeChange}
          style={{ marginRight: 5, width: "210px" }}
          options={timeRangeOptions}
          dropdownStyle={{ borderRadius: "8px" }}
        />
      </div>
      {isRemoteVitalsDataError ? (
        <CommonErrorMessage message="There was an error loading the Remote Vitals data." />
      ) : (
        <>
          {selectedTab === RemoteVitalsTypes.BloodPressure && (
            <BloodPressureTable
              data={
                bloodPressureData
                  ? (bloodPressureData as BloodPressureVitals[])
                  : []
              }
              loading={isLoadingRemoteVitalsData || refetchRemoteVitalsLoading}
              filter={lableRange}
              setTypeFilter={setTypeFilter}
              typeFilter={typeFilter}
              setShowVitalsCharts={setShowVitalsCharts}
              selectedTab={selectedTab}
            />
          )}
          {selectedTab === RemoteVitalsTypes.Weight && (
            <WeightTable
              data={weightData ? (weightData as WeightVitals[]) : []}
              loading={isLoadingRemoteVitalsData || refetchRemoteVitalsLoading}
              filter={lableRange}
              setTypeFilter={setTypeFilter}
              typeFilter={typeFilter}
              setShowVitalsCharts={setShowVitalsCharts}
              selectedTab={selectedTab}
            />
          )}
        </>
      )}
    </div>
  );
};
